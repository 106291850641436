// +----------------------------------------------------+
// | Primitive Styles for Formly Rendering              |
// +----------------------------------------------------+
$primitive-indentation: 33%;
$field-width: 67%;
$input-width: 300px;

.uw-question {
  padding: 10px;
  font-size: 14px;
  background-color: white;

  // TODO: Temporal to show the sliding comment gesture icon
  .question-comment-green {
    right: -8px !important;
    top: -8px;

    .vertical-outline {
      height: 300px;
    }
  }

  // Material Input field behavior prevention
  // 1. Initial padding
  ion-item {
    --padding-start: 0px;
    --ion-safe-area-left: 0px;
    --inner-padding-inline-start: 0px;
    --padding-inline-start: 0px;
    //--background: var(--vrsk-light-silver-background);
    --background: var(--vrsk-white-color);
    width: 100%;
  }

  // Material paddings and underline defeating
  .mat-form-field-wrapper {
    padding-bottom: 1em;

    // 2. Underline
    .mat-form-field-underline {
      background-color: transparent;
    }

    // 3. Underline when error
    .mat-form-field-ripple {
      background-color: transparent;
    }

    // 4. Top/Bottom input box padding
    .mat-form-field-infix {
      padding: 0;
    }
  }

  // Minimalistic row / column
  .primitive-row {

    // Column Primitive
    .primitive-column {
      .prop-label {
        margin-top: 0px; // Labels are hidden inside columns
      }
    }
  }

  // Header Row
  .primitive-row-header {

    // Column Primitive
    .primitive-column {
      background-color: lightgray;
      border: 1px solid gray;

      .prop-label {
        margin-top: 0px; // Labels are hidden inside columns
        font-weight: 600; // Bold font size
        padding-left: 5px; // A label in a table has some padding
        padding-right: 5px;
      }
    }
  }

  // Row with columns with borders
  .primitive-row-data {
    background-color: white; // nth-child(2n)

    // Column Primitive
    .primitive-column {
      border: 1px dotted grey;

      // Don't show labels for radio/checkbox primitives - It should be in the column header
      .primitive-radio>.prop-label {
        display: none !important; // Label will be hidden
      }

      .primitive-checkbox>.prop-label {
        display: none !important; // Label will be hidden
      }

      // Adjustments for input primitive
      .primitive-input {
        padding-top: 10px;
        padding-right: 5px;
        flex-wrap: nowrap;
      }

      .primitive-input>.prop-label {
        display: none !important; // Label will be hidden
      }

      .primitive-input>.prop-input {
        flex: 1 1 auto; // Small space: allow shrink (usually disabled)
        min-width: 10%; // Label will be hidden. We don't want the usual 40%
        padding-left: 5px;
        padding-right: 5px;
      }

      // For empty labels inside a column
      .prop-label {
        margin-top: 0px; // Labels are hidden inside columns
        padding-left: 5px; // A label in a table has some padding
      }
    }
  }

  // Column Primitive
  .primitive-column {

    // Input labels inside a column are hidden
    .primitive-input {
      .prop-label {
        display: none !important;
      }
    }
  }

  // Two related inputs in one line
  .primitive-related-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .prop-label {
      flex: 0 0 $primitive-indentation;
      max-width: $primitive-indentation;
      display: flex;
      align-items: center;
      padding-right: 20px;
    }

    .prop-text {
      padding: 0 10px 0;
      display: flex;
      align-items: center;
    }

    .prop-prefix {
      padding: 0 10px 0 0;
      display: flex;
      align-items: center;
    }

    .prop-suffix {
      padding: 0 0 0 10px;
      display: flex;
      align-items: center;
    }

    .prop-from {}

    .prop-to {}
  }

  .primitive-multicheck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .prop-label {
      flex: 0 0 $primitive-indentation;
      max-width: $primitive-indentation;
      display: initial !important;
      padding-top: 16px;
      padding-right: 20px;

      div {
        font-size: var(--vrsk-font-size-medsmall);
        color: black;
        font-family: var(--vrsk-fontFamily);
      }
    }

    .prop-multicheck {
      flex: 1 0 auto;
      // Checkboxes have no left padding unlike radio buttons
      padding-left: 2px;

      // Ionic multicheckbox
      .prop-checkbox-container {
        display: flex;
        flex-wrap: wrap;

        .prop-checkbox-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 5px;
          margin-bottom: 5px;

          ion-checkbox {
            --size: 24px;
            --checkbox-background-checked: var(--vrsk-blue1);
            // box-shadow: 0px 2px 4px 0px #00000026;
            --background: #FFFFFF;
            width: 35px;
            height: 35px;
            border-radius: 3px;
            border: 2px solid #00000026
          }

          ion-label.prop-checkbox-label {
            font-size: var(--vrsk-font-size-medsmall);
            //color: #666666;
            color: #000000;
            font-family: var(--vrsk-fontFamily);
            // Expand label to fully show
            display: flex;
            align-items: center;
            padding: 5px;
           // flex: 1 1 auto;
            white-space: normal !important;
          }
        }
      }

      ::part(container) {
        border-radius: 3px;
        // border: 2px solid var(--vrsk-blue1);
        border: solid 1px var(--vrsk-light-gray6);
      }

      .checkbox-icon {
        background: var(--vrsk-blue1);
      }
    }

    .checkbox-vertical-align .prop-checkbox-container {
      display: block;
    }

    .prop-comment-checkbox {
      flex: 1 1 100%;
      max-width: 100%;
      padding-left: $primitive-indentation;
      padding-right: 10px;
    }
  }

  // Radio Primitive Style for Formly Rendering
  .primitive-radio {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    flex-wrap: wrap; // To align prop-other-input to next row changing this one

    .prop-label {
      flex: 0 0 $primitive-indentation;
      max-width: $primitive-indentation;
      display: initial !important;
      padding-top: 2px;
      padding-right: 20px;
    }

    .prop-radio {
      display: flex;
      flex-basis: $field-width;
    }

    .prop-radio.prop-toggle {
      justify-content: flex-end;
    }

    .radio-vertical-align .prop-radio-container .horizontal-radio-div {
      display: block;
    }

    .prop-other-input {
      padding: 10px 10px 5px 0px;
      display: flex;
      flex-basis: 100%;
      justify-content: flex-end;

      .mat-form-field-infix {
        width: 100%;
      }

      app-formly-uw-field-input {
        ion-item {
          width: $input-width;
        }
      }
    }

    div {
      font-size: var(--vrsk-font-size-medsmall);
      color: black;
      font-family: var(--vrsk-fontFamily);

      ion-item {
        width: auto;
      }
    }
  }

  .primitive-textarea {
    // width: 80%;
    // padding-left: 20%;
    padding-right: 10px;
  }

  .primitive-compact {
    display: block;
    height: 40px;
  }

  // Input Primitive Style for Formly Rendering
  .primitive-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px 0px 2px 0px;

    .prop-label {
      flex: 0 0 $primitive-indentation;
      max-width: $primitive-indentation;
      display: flex;
      align-items: center;
      padding-right: 20px;

      div {
        font-size: var(--vrsk-font-size-medsmall);
        color: black;
        font-family: var(--vrsk-fontFamily);
      }
    }

    .prop-prefix {
      display: flex;
      align-items: center;
      max-width: 20%;
      text-overflow: ellipsis;
      margin-right: 0.2em;

      span {
        font-size: var(--vrsk-font-size-medsmall);
        color: var(--vrsk-dark-gray-color);
        font-family: var(--vrsk-fontFamily);
      }
    }

    .prop-input {
      // Input will grow to fill available space
      flex: 1 0 auto;
      min-width: 40%;
    }

    .prop-suffix {
      display: flex;
      align-items: center;
      max-width: 20%;
      text-overflow: ellipsis;
      margin-left: 0.2em;

      span {
        font-size: var(--vrsk-font-size-medsmall);
        color: var(--vrsk-dark-gray-color);
        font-family: var(--vrsk-fontFamily);
      }
    }

    .prop-command-input {
      flex: 1 1 100%;
      max-width: 100%;
      padding-left: $primitive-indentation;
      padding-right: 10px;
    }
  }

  // Time range Primitive Style for Formly Rendering
  .primitive-timerange {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    // margin-right: var($primitive-indentation);

    .prop-label {
      flex: 0 0 $primitive-indentation;
      max-width: $primitive-indentation;
      display: initial !important;
      // flex: 0 0 20%;
      // max-width: 20%;
      padding-top: 16px;
      padding-right: 20px;
    }

    .prop-from {
      flex: 1 0 auto;
      color: rgba(0, 0, 0, 0.54);
      padding: 2px 3px;
      font-size: 11.5px;
    }

    .prop-to {
      flex: 1 0 auto;
      color: rgba(0, 0, 0, 0.54);
      padding: 2px 3px;
      font-size: 11.5px;
    }

    .prop-days {
      flex: 0 0 auto;
      color: rgba(0, 0, 0, 0.54);
      padding: 2px 3px;
      font-size: 12px;
    }
  }

  .primitive-text {
    .prop-label {
      padding-right: 10px;
      padding-top: 32px;
    }

    h4,
    h3,
    p {
      font-size: var(--vrsk-font-size-medsmall);
      color: black;
      font-family: var(--vrsk-fontFamily);
    }
  }

  .primitive-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .prop-label {
      flex: 0 0 $primitive-indentation;
      max-width: $primitive-indentation;
      display: initial !important;
      padding-top: 2px;
      padding-right: 20px;
      font-size: var(--vrsk-font-size-medsmall);
      color: black;
      font-family: var(--vrsk-fontFamily);
    }

    .prop-field {
      width: 66.9%;
    }

    .prop-field-description {
      color: rgba(0, 0, 0, 0.54);
      padding-top: 7px;
      font-size: 12px;
    }

    textarea {
      font-size: 17px;
      border: 1px solid var(--vrsk-light-gray5);
      border-radius: 3px;
      caret-color: black;
      padding-left: 5px;
    }

    textarea[aria-describedby^="mat-error"],
    textarea[aria-describedby*=" mat-error"] {
      border: solid 2px #EE3932;
    }

    textarea.ng-touched.ng-invalid {
      border: solid 2px #EE3932;
    }

    formly-field-mat-select {
      border: 1px solid var(--vrsk-light-gray5);
      border-radius: 3px;
      padding: 10px;
      caret-color: black;
    }

    ion-radio-group ion-item {
      width: auto;
    }
  }

  /* Question Comment */
  .primitive-comment {
    display: none;
  }
}


.uw-section {
  .prop-label {
    div {
      color: black;
    }
  }

  // .primitive-radio {
  // .prop-radio {
  //    ion-list,ion-item{
  //     background-color: var(--vrsk-light-silver-background);
  //     --background:var(--vrsk-light-silver-background);
  //    }
  //   }
  // }
}

.input-item-container {
  width: 100%;
  display: grid;
  justify-content: flex-end;

  .input-style {
    width: 200px;
    font-size: 17px;
    border: 1px solid var(--vrsk-light-gray5);
    border-radius: 3px;
    caret-color: auto;
    --padding-start: 10px !important;
    --padding-end: 10px !important;
    display: flex;
    align-items: center;
    height: 35px;
  }

  .input-disabled {
    background: #999999;
  }

  .input-error {
    border: solid 2px #EE3932;
  }

  .vertical-align {
    display: flex;
    vertical-align: middle;
  }

  ion-item {
    --inner-padding-end: 0px;
  }

  ion-grid {
    padding: 0px;
    padding-right: 1px;
  }


  .error-message {
    .validation-error {
      font-family: 'Roboto';
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      color: #EE3932;
    }
  }


}

.table-content .input-item-container {
  width: 100%;
  display: grid;
  justify-content: flex-start;
  padding-top: 23px;
}


.select-item-container {
  width: 100%;
  display: grid;
  justify-content: flex-end;


  .select-container {
    width: 200px;
  }

  .select-style {
    max-width: 100%;
    font-size: 17px;
    border: 1px solid var(--vrsk-light-gray5);
    border-radius: 3px;
    caret-color: auto;
    --padding-start: 10px !important;
    --padding-end: 10px !important;
    display: flex;
    align-items: center;
    height: 35px;
  }

  .uw-question .primitive-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px 10px 2px 0px;
  }


  .select-error {
    border: solid 2px #EE3932;

  }


  ion-item {
    --inner-padding-end: 0px;
  }

  ion-grid {
    padding: 0px;
    padding-right: 1px;
  }


  .custom-popover {
    --min-width: 400px;
    --offset-x: -20px;

    &::part(content) {
      border-radius: 5px;
      border: 1px solid gray;
    }

    &::part(arrow) {
      --offset-x: 0px;
    }
  }

  .width-margin {
    width: 238px;
  }

  .select-disabled {
    background: #999999;
    opacity: 1;
  }


  .available-data-title {
    --inner-padding-start: 10px;
    --inner-padding-top: 15px;
    --inner-padding-bottom: 15px;

    font-size: 18px;
  }

  .option {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
  }

  .title-option {
    font-size: 18px;
  }

  .content-option {
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 12px;
    color: gray;
  }

  .error-message {
    .validation-error {
      font-family: 'Roboto';
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      color: #EE3932;
    }
  }

}

.table-content .select-item-container {
  padding-top: 33px;
  padding-left: 4px;
}

.table-content .primitive-field>formly-field.prop-label {
  display: none !important;
}