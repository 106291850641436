/*
 * Risk Report SCSS stylesheet
 * ----------------------------------------------------------------------------
*/

// Class for formly group items allignments
.formly-flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ion-select {
    width: 100% !important;
  }

  .right-align {
    margin-left: auto;
  }

  .grow-right-align {
    margin-left: auto;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .new-row {
    flex-basis: 100%;
    height: 0;
    padding: 0px;
  }

  .fill-row {
    flex: 1;
    height: 0;
    padding: 0px;
  }

  formly-field {
    padding-right: 10px;
    padding-bottom: 10px;

    // Simple input is having extra 10px margin when it was inside the formly flex container so removing it
    app-formly-field-simple-input {
      .field-container.field-input-container {
        margin-right: 0px;
      }
    }
  }

  .field-padding-bottom-0 {
    padding-bottom: 0px;
  }

  .field-padding-bottom-10 {
    padding-bottom: 10px;
  }

  .image-margin {
    margin-top: 22px;
  }

  .text-area {
    padding-right: 0px;
    text-wrap: wrap;
    max-width: 149px;
  }

  .text-value {
    white-space: break-spaces;
    font-size: 17px;
  }

  .full-width-select {
    height: 60px;
    width: 100%;

    .field-extended-select-container {
      justify-content: start;
      margin-right: 10px;

      &.card-style {
        box-shadow: var(--vrsk-box-shadow3);
        border-radius: var(--vrsk-border-radius);
        padding: 0px 10px 0px;
        min-height: 60px;
        margin-bottom: 3px;

        ion-label {
          max-width: none;
          // padding-left: 10px;
        }

        ion-item.item {
          margin-left: auto;
          width: var(--vrsk-field-width-l);

          ion-select {
            width: 100%;
            // --padding-start: 0px !important;
          }
        }
      }
    }
  }

  .right-aligned-field {
    display: flex;
    justify-content: end;
    flex: 1;
  }

  .floorFrom {
    margin-left: 8px;
    padding-right: 4px;
    width: 114px !important;
    position: relative;
    z-index: 999;

    ion-select {
      max-width: 100%;
    }
  }

  .calculated-value .value-style {
    position: fixed;
    top: 54px;
  }

  .calculated-hazards .value-style {
    position: fixed;
    top: 54px;
  }
  

  .sd-checkbox-style {
    margin-top: 12px;
    --ion-background-color: $white-color;
  }

  .timber-column-width {
    width: 190px;
    --ion-background-color: $white-color;

    app-formly-field-checkbox ion-grid {
      .checkbox-label {
        top: 10px !important;
      }
    }
  }

  .checkbox-white {
    --ion-background-color: $white-color;
  }

  .insulationCheckbox {
    padding-top: 35px;
    // padding-right: 0px;
  }

  .text-message {
    margin-top: 50px;
  }

  .equals-to {
    margin-top: 33px;
    font-size: 17px;
    font-weight: 700;
    color: var(--vrsk-black-color);
    padding-right: 3px;
  }

  .vertical-opening-slider {
    padding-bottom: 0px;
    padding-top: 10px;
    width: 324px;
  }

  //------- Place to keep Width classes -----
  //input width
  // width 84px
  .input-width-xs {
    width: 94px;
  }

  .input-width-xxs {
    width: 80px;
  }
  // width 238px
  .input-width-l {
    width: 248px;
  }

  //width 114px
  .input-width,
  .smd-width {
    width: 124px;
  }

  // width 142px
  .input-width-xl {
    width: 142px;
  }

  .input-width-126 {
    width: 126px;
  }

  .input-width-307 {
    width: 317px;
  }

  .select-width-115 {
    width: 115px;
  }

  .select-width-183 {
    width: 183px;
  }

  .select-width-250 {
    width: 260px;
  }

  .select-width-198 {
    width: 198px;
  }

  // select-width
  .select-width-md {
    width: 248px !important;
    position: relative;
    z-index: 999;

    ion-select {
      max-width: 100%;
    }
  }

  .select-width-custom {
    width: 195px !important;
    position: relative;
    z-index: 999;

    ion-select {
      max-width: 100%;
    }
  }

  .select-width-smd {
    width: 198px !important;
    position: relative;
    z-index: 999;

    ion-select {
      max-width: 100%;
    }
  }

  .select-width-sm {
    width: 124px !important;
    position: relative;
    z-index: 999;

    ion-select {
      max-width: 100%;
    }
  }

  .select-width {
    width: 186px;

    ion-select {
      width: 100%;
    }
  }

  // Titles for blocks
  .block-title {
    h2 {
      font-size: 17px !important;
      font-weight: 500 !important;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .internal-card-style {
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    padding: 0px 10px 0px;
    min-height: 60px;
    margin-bottom: 3px;
    display: flex;
  }

  .internal-card-row {
    display: inline-flex;
    width: 100%;
    flex: 1;
  }

  .section-block {
    margin-right: 21px;
    display: flex;
    height: var(--vrsk-section-header-height);

    .block-inline-title {
      margin-bottom: 0px;
      height: 60px;
    }
  }
}

// swipe gesture background
.slider-background-blue {
  background-color: var(--vrsk-primary-blue);
}

.slider-background-error {
  background-color: var(--vrsk-error);
}

// A form group inside of a question wrapper will be inline
.question-wrapper-inline {
  display: inline-flex;
  align-items: center;

  // Input field specific inline behavior
  div.field-input-container {
    margin-right: 0px;
  }

  // Select?
  // ...
}

.outside-section {
  .main-section-item {
    // padding-bottom: 15px;    Modified in simple-section-wrapper for section-content-row
    background-color: white;
    --padding-end: 5px;

    .main-section-item {
      padding-bottom: 0;
      --padding-end: 0;
    }
  }

  // simple section contanier inside simple select
  .simple-section-container {
    // Want only not inside question wrapper
    .field-extended-select-container.card-style {
      margin: 0 15px 0 5px;
    }

    // #ion-overlay-4 > app-formly-generic-sheet > div > ion-content > div > div > formly-field > app-formly-simple-section-wrapper
    // > div > ion-item > ion-grid > ion-row > ion-col > ion-row:nth-child(2) > ion-col > formly-group > formly-field.select-full-width.margin-bottom-small
    // > app-formly-field-simple-select > div
    .margin-bottom-small {
      div.field-extended-select-container {
        margin-bottom: 5px !important;
      }
    }
  }
}

// class to be added for select with full width
.select-full-width {
  .field-extended-select-container.card-style {
    justify-content: space-between;
    padding: 4px 10px 3px 10px;
  }
}

// common custom grid width of column for system component sprinkler
app-formly-common-custom-grid-view {
  .common-grid-view-width-80 {
    width: 80px;
  }

  .common-grid-view-width-145 {
    width: 145px;
  }

  .common-grid-view-width-100 {
    width: 100px;
  }

  .common-grid-view-width-115 {
    width: 115px;
  }

  .common-grid-view-width-88 {
    width: 88px;
  }

  .common-grid-view-width-120 {
    width: 120px;
  }

  .common-grid-view-width-195 {
    width: 195px;
  }

  .common-grid-view-width-105 {
    width: 105px;
  }

  .common-grid-view-width-300 {
    width: 300px;
  }

  .common-grid-view-width-60 {
    width: 60px;
  }

  .common-grid-view-width-auto {
    width: auto;
  }
}


// Used for Warning Toaster
.gold-toaster {
  --color: var(--vrsk-light-gray);
  width: 694px;
  --border-style: solid;
  --border-width: 1px;
  font-size: $font-size-nxl;
  font-style: $font-weight-bold;
  right: 10px;
  left: auto;
  bottom: 10px;
  top: auto;
  --background: #F7ECC4;
  --border-color: #FFC600;

  // img {
  //   margin-right: 10px;
  //   width: 20px;
  //   height: 16px;
  // }
  // .toast-layout-baseline {
  //   display: flex;
  //   align-items: center;
  //   flex-direction: row;
  // }
  &::part(button) {
    text-decoration-line: underline;
    --button-color: var(--vrsk-light-gray);
    margin-right: 25px;
  }

  &.error-toast {
    --background: var(--vrsk-error);
    --color: var(--vrsk-white-color);
  }

  &::part(message) {
    text-align: center;
  }

  // Use this for Gold toasters
  &.warning-toaster {
    width: 70%;
    right: 36px;

    &::part(message) {
      text-align: left;
      margin-left: 22px;
      display: flex;

      ::part(icon) {
        margin-right: 10px;
        width: 20px;
        height: 16px;
      }

      .toast-content {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }

    &::part(container) {
      align-items: flex-start;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &::part(button) {
      margin-right: 15px;
      font-size: 20px;
      line-height: 20px;
      margin-top: 22px;
    }
  }

  .warning-toaster {
    .toast-button-group {
      display: flex;
      min-height: 110px
    }

    .toast-button {
      font-size: 20px;
    }
  }
}

// TODO: Cleanup. Add specificity
.custom-disabled-background {
  opacity: 1;
  background: var(--vrsk-light-gray5);
}

.custom-disabled-red-color {
  color: var(--vrsk-error) !important;
  background: var(--vrsk-light-gray5);
  opacity: 1;
}

.custom-enabled-background {
  opacity: 1;
  background: var(--vrsk-white-color);
}

// Temporary fix for popover with invalid value
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.formly-button {
  margin: 5px;
}

.max-z-index {
  z-index: 999;
}

.position-relative {
  position: relative;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.margin-top {
  margin-top: 10px !important;
}

.margin-bottom {
  margin-bottom: 20px !important;
}

.qw-label-normal {
  .question-wrapper-container {
    .section-label {
      opacity: 1;
    }
  }
}

// +----------------------------------------------------------------------+
// | Please put section-dependant rules below; generic ones above         |
// +----------------------------------------------------------------------+

// Needed for Contact Information
.card-view {
  display: flex;
  padding: 10px 10px 10px 10px;
  box-shadow: var(--vrsk-box-shadow3);
  border-radius: var(--vrsk-border-radius);

  .name-width {
    width: 186px;
  }

  .phone-width {
    width: 156px;
  }
}

//style for occupunacy building hazards fields
.occupant-hazards-fields {
  formly-group {
    display: flex;
    flex-wrap: wrap;

    app-formly-field-search-bar {
      ion-searchbar {
        width: 230px;
        height: 41px;
        margin-right: 20px;
      }

      ion-label {
        span {
          display: none;
        }
      }
    }

    .building-hazards-description-field {
      width: 100%;
      flex: 1;

      ion-input {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .building-hazards-comment-field {
      margin-top: 15px;
      width: 100%;
      margin-right: 10px;
      .field-input-block {
        color: black;
        font-size: 17px;
      }
      ion-textarea {
        textarea {
          padding-left: 10px;
        }
      }
    }

    .hazards-photo-field {
      margin-top: 15px;
      width: 100%;
    }
  }
}

.walls-noncombustible {
  .wall-combustible-or-text {
    padding-left: 10px;
  }
}

.nccard-view {
  margin-bottom: 3px;
  margin-right: 15px;
  --ion-background-color: $white-color;
  box-shadow: var(--vrsk-box-shadow3);
  border-radius: var(--vrsk-border-radius);
  display: block;
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;

  .mcm-styles {
    .grid-style .checkbox-row {
      ion-checkbox {
        margin: 0px !important;
      }

      .checkbox-label {
        top: 11px !important;
      }
    }
  }

  .nccard-child-view {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 20px;
    --ion-background-color: $white-color;
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    display: flex;
    flex-wrap: wrap;

    .ncFlameSpread {
      margin: 20px 0px 20px 52px;
    }

    .ncSmokeDev {
      margin: 20px 0px 20px 0px;
      padding-right: 0px;
    }

    ion-input {
      width: 114px;
    }
  }
}

// Styles for Water Supply
.ws-title-style {
  font-size: 17px;
  font-weight: var(--vrsk-font-weight-medium);
  margin-top: 32px;
  margin-bottom: 26px;
}

//styles for water-supply- fire pump
.fire-pump-evaluation-container {
  app-formly-field-checkbox {
    .grid-style.card-style {
      ion-row {
        .checkbox-row {
          .checkbox-label {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

// Styles for Water Supply - Supply Source
.supply-source-container {
  .supply-source-content {
    .date-picker {
      .date-picker-container {
        .section-label {
          padding-bottom: 2px;
        }
      }
    }

    .fire-pump-test-section-container {
      width: 100%;
      padding-bottom: 0px;

      .simple-section-container {
        .item.main-section-item {
          --padding-start: 0px;
          margin: 0px;
        }

        .section-title-row {
          padding-right: 10px;
        }

        .default-contentStyle {
          margin-right: 10px;
        }
      }
    }

    .ss-input-padding {
      .field-input-block {
        input {
          padding-right: 0px;
        }
      }
    }

    .pressure-tank {
      width: 244px;
      display: flex;
      align-items: center;
      padding-bottom: 0px;
      padding-top: 9px;
    }

    .ss-location-width {
      width: 380px;
    }

    .stored-volume.ss-input-padding {
      .field-label {
        .required-star {
          margin-left: 0px;
        }
      }
    }
  }
}
.fire-pump-testing-container{
  .fire-pump-test-section-container {
    .simple-section-container {
      .item.main-section-item {
        --inner-padding-end: 20px;
        --inner-padding-bottom: 20px;
        margin-right: 0px;
        box-shadow: var(--vrsk-box-shadow3);
        border-radius: var(--vrsk-border-radius);
        ion-row.section-title-row {
          padding-right: 0px;
        }
      }
    }
  }
}

// Styles for chargeable column
.thickness-width {
  width: 128px;
  margin-left: auto;
}

.walls-bearing-style {
  width: 149px;
  padding-right: 0px !important;

  ion-label {
    width: 139px;
  }

  ion-input {
    width: 139px;
  }
}

.label-weight {
  font-weight: 500;
  font-size: 17px;
}

.column-card-view {
  display: flex;
  padding: 10px 0px 10px 10px;
  box-shadow: var(--vrsk-box-shadow3);
  border-radius: var(--vrsk-border-radius);
}

.column-width {
  width: 100%;
  padding-right: 0px !important;
}

.column-style {
  padding: 10px 10px 10px 10px;
}

.in-thickness-width {
  width: 128px;
  margin-left: auto;
}

// Styles for chargeable panels
.percent-width {
  width: 170px;
  margin-left: auto;
  margin-top: 7px;
}

.masonry-style {
  margin-left: auto;
}

.panel-card {
  box-shadow: var(--vrsk-box-shadow3);
  border-radius: var(--vrsk-border-radius);
  padding-left: 10px;
}

.sheet-toolbar-style {
  --background: #00358e;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 0px solid #00358e;
  border-top-left-radius: 10px;

  .title-style {
    padding-top: 25px;
    font-size: 18px;
    font-weight: 300;
    line-height: 21.09px;
  }

  ion-title {
    text-align: left !important;
    float: left;
    padding: 0px 45px;
    position: unset;
    width: max-content;
    font-weight: 700;
  }

  .btns {
    text-align: right;
    padding-right: 45px;
    margin-bottom: 10px;

    .action-btn {
      height: 41px;
      font-size: 17px;
      --border-radius: 25px;
      --border-width: 1px;
      text-align: center;
      font-weight: 500;
      line-height: 20;
      margin-left: 7px;
    }

    .add-btn {
      width: 137px;
      border: 1px dashed #ffffff;
      border-radius: 25px;
      --background: none !important;
      height: 41px;
    }

    .apply-btn {
      --background: #ffffff !important;
      --color: #00358e !important;
      width: 148px;
    }

    .close-btn {
      border: 1px solid #ffffff;
      border-radius: 25px;
      --background: none !important;
      width: 104px;
    }
  }

  .handle-bar {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;

    span {
      width: 40px;
      height: 5px;
      background-color: #fff;
      margin: 0 auto;
      border-radius: 10px;
      display: block;
    }
  }
}

.padding-right-left-40 {
  padding: 0px 40px;
}

.ft-label-input ion-input {
  &::after {
    content: "ft";
    margin-right: 10px;
  }
}

.water-supply-evolution-container {
  .yes-no-styling {
    app-formly-field-yes-no {
      div {
        justify-content: space-between;
        margin-top: 0px !important;

        ion-segment {
          margin-right: 0px !important;
          margin-left: 0px !important;
        }

        ion-label {
          max-width: 100% !important;
        }

        .field-container {
          margin-bottom: 0px !important;
          margin-top: 0px !important;
        }
      }
    }
  }
  .sole-source-dependency {
    .prop-radio-container.card-style {
      padding: 0px 5px;
    }
  }
}

.hydraulic-rack-storage-container {
  .l-m-left {
    .checkbox-label {
      margin-left: 0px !important;
    }
  }
}

.system-design-calculate-modal {
  --background: transparent;
  --backdrop-opacity: 0.5 !important;
}

.system-design-calculate-modal .ion-page {
  top: 25%;
  height: 80vh;
}

.hydraulic-system-design-container {
  display: block;
  padding-right: 23px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-left: -7px;

  .prop-radio-container .horizontal-field-group ion-radio {
    margin-inline-end: 5px !important;
  }

  .hydraulic-select-design {
    app-formly-field-simple-select {
      ion-select {
        width: 464px;
      }
    }
  }

  .hydraulic-select-hose-stream {
    app-formly-field-simple-select {
      .field-extended-select-container .field-label {
        margin-right: auto;
      }

      ion-select {
        width: 114px !important;
      }

      ion-item {
        margin-right: -10px;
      }

      .field-extended-select-container {
        margin-bottom: 5px;
        margin-top: -10px;
      }
    }
  }

  .margin-supply-duration {
    app-formly-field-simple-select {
      .field-extended-select-container {
        margin-bottom: -10px;
      }
    }
  }

  .remove-margin {
    app-formly-simple-section-wrapper {
      ion-item {
        margin-left: 0px;
        margin-right: 0px !important;
        --padding-start: 6px;
      }
    }
  }

  .non-numeric-design {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: -7px;

    // .hydraulic-design-density {
    //   app-formly-question-wrapper {
    //     ion-grid.padding-right {
    //       padding-right: 4px;
    //     }
    //   }
    // }
    app-formly-question-wrapper {
      // app-title{
      ion-item.question-wrapper-ion-item {
        --padding-end: 0px;
      }

      // }
    }

    .field-extended-input-container .field-input-wrapper {
      width: var(--vrsk-field-width-ls) !important;
    }

    app-formly-field-simple-input {
      .field-extended-input-container .unit-suffix {
        font-size: 13px;
      }
    }

    .sys-design-row-1 {
      padding: 0px 8px 3px 0px;
    }

    .area-margin {
      app-title {
        .normal-section-label {
          margin-left: 3px;
        }
      }
    }

    .padding-r {
      padding-right: 10px !important;
      app-formly-question-wrapper {
        ion-item {
          ion-col ion-item{
            --padding-start: 0px;
          }          
        }
      }    

      .field-extended-input-container {
        margin-right: -2px;
      }
    }

    .sys-design-row-2 {
      padding: 0px 8px 5px 0px;

      app-formly-question-wrapper {
        ion-item {
          --padding-start: 10px;
        }
      }
    }

    ion-item {
      margin: 0px 0px 0px 0px !important;
    }

    app-formly-question-wrapper {
      width: 100%;
    }

    .width {
      width: 50%;
    }

    .margin-input {
      app-formly-field-simple-input {
        .field-extended-input-container {
          margin-right: -6px;
        }

        .field-extended-input-container .field-input-wrapper ion-input {
          --padding-end: 1px;
        }
      }

      app-formly-question-wrapper {
        app-title {
          .section-label {
            font-weight: 400 !important;
          }
        }

        // ion-item-sliding {
        //   padding-left: 3px;
        // }
      }
    }

    .new-row {
      flex-basis: 100%;
      height: 0;
      padding: 0px;
    }
  }

  .hyd-sys-design {
    display: block !important;
    padding: 0px 20px 0px 10px;
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
  }

  .hos-demand-margin {
    app-formly-field-radio {
      .prop-radio-container .horizontal-radio-div {
        margin-right: 20px;
      }
    }

    .prop-radio-container {
      .horizontal-item {
        margin-right: 11px;
      }
    }
  }

  .remove-padding {
    app-formly-field-radio {
      .prop-radio-container.card-style {
        padding: 0px 5px !important;

        ion-radio-group {
          div.radio-background-circle {
            margin-left: 7px !important;
          }
        }
      }
    }
  }

  .demand-at-padding {
    .prop-radio-container {
      .horizontal-item {
        margin-left: 3px !important;
        margin-right: -19px;
      }
    }
  }

  .info-margin-right {
    app-formly-field-radio {
      .prop-radio-container .horizontal-radio-div {
        margin-right: 40px;
      }
    }
  }

  .radio-shading {
    app-formly-field-radio {
      ion-radio-group {
        div.radio-background-circle {
          margin-left: 7px !important;
        }
      }

      ion-radio-group {
        margin-bottom: -5px;
      }
    }
  }

  .left-padding-radio {
    .prop-radio-container {
      ion-item::part(native) {
        --padding-start: 0px !important;
      }

      // .radio-circles-block {
      //   margin-right: -7px !important;
      // }
    }
  }
}

.system-design-calculate-container {
  app-formly-simple-section-wrapper {
    .simple-section-container {
      ion-item {
        margin-top: 0px !important;
      }
    }
  }

  .simple-section-container.item {
    margin-right: 23px !important;
  }

  .field-extended-input-container {
    margin-right: 20px !important;
  }

  .ws-title-style {
    font-size: 17px;
    font-weight: var(--vrsk-font-weight-medium);
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .instruction-text-style {
    margin-top: -20px;
    height: 32px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding-right: 35px;
  }

  .comb2-margin {
    .field-extended-input-container.card-style {
      margin-bottom: 20px;
    }
  }
}
.grid-margin{
  margin-left: 15px;
  margin-right: -30px;
}

.hydraulic-data-design-container {
  div {
    ion-card {
      app-swipeable-card {
        ion-item-sliding {
          app-remove-icon {
            ion-button {
              margin-top: -10px;
              margin-left: -2px;
            }
          }
          div.fields-wrapper{
            margin-top: 5px;
          }
        }
      }
    }
  }

  .data-design-field-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3px;
    margin-left: -3px;
    padding-bottom: 19.93px;

    .system-number-input {
      padding-bottom: 20px;

      ion-input {
        width: 57px;        
      }
    }

    .design-mt {
      app-formly-field-simple-select {
        ion-item.item-select-field {
          margin-right: 10px;
        }

        ion-select {
          width: 171px;          
        }       
      }
    }

    .desc-input {
      ion-input {
        width: var(--vrsk-field-width-l);        
      }
    }

    .area-input {
      ion-input {
        width: var(--vrsk-field-width-m);        
      }
    }
   
    .medium-width {
      ion-input {
        width: var(--vrsk-field-width-m);
      }
    }
  }
}

.secSupplyRadio {
  .prop-radio-container {
    margin-right: -10px !important;

    ion-radio {
      margin-right: 0px !important;
    }
  }
}

.fire-pump-test-container {
  .simple-section-container ion-item.item {
    margin-bottom: 0px !important;

    .date-picker-container {
      .date-input-section .date-input-wrapper {
        width: 114px !important;
      }
    }
  }

  .fire-pump-style {
    app-formly-question-wrapper {
      .question-wrapper-container {
        .item.item-shadow-extended-input {
          margin: 0px;
          margin-bottom: 3px !important;
          padding-top: 3px;

          .field-extended-input-container .field-input-wrapper {
            margin-left: 5px;

            .unit-suffix {
              font-size: 13px;
            }
          }
        }

        .card-view {
          &.no-box-shadow {
            box-shadow: none;
          }

          &.no-padding {
            padding: 0px;
          }

          ion-item ion-label {
            padding-right: 2px;
          }
        }
      }
    }
  }

  .pump-type-style {
    .field-extended-select-container.card-style {
      margin: 0px;
    }
  }

  .fire-pump-input-width {
    .field-extended-input-container ion-item .field-input-wrapper {
      width: 238px;
    }
  }

  .error-icon {
    top: -70px !important;
    right: -20px !important;
  }

  .pump-power-style {
    .prop-radio-container.card-style {
      height: 60px;
    }
  }
  .rightAlignSupervision .field-container .segment-disabled.rightAlign {
    margin-left: auto;
  }
}

.fire-pump-test-results-container {
  .simple-section-container ion-item.item {
    margin-top: 0px !important;
    padding-top: 10px;
  }

  .fire-pump-input-field {
    .field-extended-input-container .field-item-wrapper {
      --padding-start: 0px;
    }

    .field-extended-input-container .field-input-wrapper .unit-suffix {
      font-size: 13px;
    }
  }

  .colwidth-122 {
    width: 122px;
  }

  .colwidth-132 {
    width: 132px;

    .field-extended-input-container .field-item-wrapper {
      width: 132px;
    }
  }

  .colwidth-92 {
    width: 92px;

    .field-extended-input-container .field-item-wrapper {
      width: 92px;
    }
  }

  .colwidth-124 {
    width: 124px;

    .field-extended-input-container .field-item-wrapper {
      width: 124px;
    }
  }
}

.main-drain-estimation-container {
  padding-right: 0px !important;

  app-formly-simple-section-wrapper {
    width: 100%;

    .simple-section-container ion-grid {
      padding-right: 20px;

      .ws-title-style {
        margin-bottom: 25px;
        margin-top: 30px;
      }

      .summary-row-container {
        margin-right: 0px;
      }

      .calculator-wrapper {
        ion-button {
          width: 98%;
          text-align: center;
          border: 1px dashed #168bf6;
          border-radius: var(--vrsk-border-radius);
          height: 41px;
          margin: 20px 4px;
        }
      }

      .main-drain-select-style {
        .field-extended-select-container {
          justify-content: start;

          &.card-style {
            box-shadow: var(--vrsk-box-shadow3);
            border-radius: var(--vrsk-border-radius);
            padding: 0px 10px 0px;
            min-height: 60px;
            margin-bottom: 3px;

            ion-label {
              max-width: none;
            }

            ion-item.item {
              margin-left: auto;
              width: 114px;

              ion-select {
                width: 100%;
                // --padding-start: 0px !important;
              }

              // Unit is off 5px...
              ion-note {
                right: -5px;
              }
            }
          }
        }
      }

      .date-picker-container {
        .date-input-section .date-input-wrapper {
          width: 114px !important;
        }
      }
    }

    // No title here - error icon should be pushed up
    app-error-info-icon {
      div.error-icon {
        top: var(--vrsk-rf-error-icon-top-shallow) !important;
      }
    }
  }
}

.main-drain-estimation-riser-wrapper {
  img {
    margin: 20px 30px;
  }
}

// PPC section styles
.public-protection-container {
  .fire-flow-label-align {
    .field-input-wrapper {
      margin-left: 6px;
    }
  }

  app-formly-question-wrapper {
    .question-wrapper-container {
      ion-item.item-shadow-extended-input {
        margin-top: 3px;
        margin-bottom: 3px;
        margin-right: 20px;
        height: 60px;
      }
    }

    app-formly-simple-section-wrapper {
      .simple-section-container {
        height: 60px;
      }
    }
  }

  app-formly-field-yes-no {
    .field-container {
      margin-bottom: 0px;
    }
  }

  .ppc-field-padding {
    padding-right: 0;
  }

  .ppc-select-margin {
    .item-select-field {
      margin-right: 10px;
    }
  }

  .search-box-container {
    margin-right: 15px;
  }
}

//Walls styles
.walltype-thickness {
  .field-container {
    .field-label {
      display: block;
    }
  }
}

//Miscellaneous secondary construction styles
.miscellaneous-secondary-construction-container {
  ion-item {
    ion-grid {
      ion-row {
        ion-col {
          app-formly-secondary-construction-sheets {
            ion-grid {
              margin-left: -5px;
            }
          }
        }
      }
    }
  }

  .select-full-width {
    ion-select {
      width: 238px;
    }

    ion-label {
      margin-top: 4px;
    }
  }

  .building-condition-field {
    app-formly-question-wrapper {
      ion-item {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        --min-height: 61px !important;
      }
    }

    app-formly-field-simple-select {
      ion-item {
        margin-right: 10px !important;
        height: 41px !important;
      }
    }

    app-title {
      ion-label {
        font-weight: var(--vrsk-font-weight-normal) !important;
        font-size: var(--vrsk-font-size-smaller) !important;
      }
    }
  }

  .card-view-miscellaneous-secondary-construction {
    display: flex;
    padding: 10px 10px 10px 10px;
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    height: 84px;
  }

  .field1-miscellaneous-secondary-construction {
    ion-item {
      width: 258px;
    }
  }

  .field2-miscellaneous-secondary-construction {
    display: flex;
    align-items: center;
    margin-top: 9px;
    margin-left: 5px;

    app-formly-field-checkbox {
      span {
        ion-checkbox {
          width: 41px;
          height: 41px;
        }

        span {
          margin-top: 3px;
        }
      }
    }
  }

  .field3-miscellaneous-secondary-construction {
    margin-left: auto;
    padding-right: 0px !important;
    margin-right: -10px;

    app-formly-field-simple-input {
      ion-input {
        width: 153px !important;
      }
    }
  }
  .aerial-icon-style {
    margin-left: 20px;
    margin-right: -20px;
    padding-right: 0px;
  }
  .formly-flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 97%;
  }
}

//green-construction styles
.green-construction-container {
  .select-green-construction {
    app-formly-question-wrapper {
      ion-item {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 3px;
        --min-height: 61px;
      }
    }

    app-formly-field-simple-select {
      ion-item {
        margin-right: 10px !important;
        height: 41px !important;
      }
    }
  }
}

// Floors-roof building information styles
.construction-class-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ion-item {
    margin: 0px 0px 0px 0px !important;
  }

  app-formly-question-wrapper {
    width: 100%;
  }

  .width {
    width: var(--vrsk-section-width-l);
    // height: 78px;
  }

  .select-width {
    width: var(--vrsk-field-width-m);
    height: var(--vrsk-field-height-md);

    ion-label {
      height: var(--vrsk-field-label-height-s);
    }
  }

  .checkbox-style {
    padding-right: var(--vrsk-field-padding-s);
    padding-bottom: var(--vrsk-field-padding-s);

    ion-checkbox {
      margin-bottom: 8.37px;
      --size: var(--vrsk-checkbox-size-m) !important;
    }
  }

  .construction-spacing-v1 {
    padding: 0px 10px 10px 2px;

    app-formly-question-wrapper {
      ion-item::part(native) {
        padding-left: var(--vrsk-field-label-height-s);
      }
    }
  }

  .construction-spacing-v2 {
    padding: 0px 10px 10px 2px;
  }

  .new-row {
    flex-basis: 100%;
    height: 0;
    padding: 0px;
  }
}

.new-row {
  flex-basis: 100%;
  height: 0;
  padding: 0px;
}

// RR Exposures
.exposures-sheet-container {
  .select-width-m {
    app-formly-field-simple-select {
      ion-item {
        width: 250px;
        margin-right: 10px;
      }
    }
  }

  .select-width {
    app-formly-field-simple-select {
      ion-item {
        width: 250px;
      }
    }
  }

  .select-width-s {
    app-formly-field-simple-select {
      ion-item {
        width: 238px;
      }
    }
  }

  .margin-l-m {
    margin-left: 40px;
  }

  .margin-r {
    padding-right: 0px;
  }

  .input-full-width {
    width: 100%;
    padding-right: 20px !important;

    .card-style {
      padding-top: 0px;

      ion-item {
        width: 100%;
        margin-top: 4px;
      }
    }

    ion-item {
      flex: 0 0 auto;
    }

    .field-extended-input-container {
      .text-nowrap {
        flex: 1;
      }
    }
  }

  .checkbox-full-width {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 0px;

    ion-row {
      width: 100%;
    }

    ion-checkbox {
      margin-right: 10px !important;
      margin-top: 15px;
    }

    .checkbox-label {
      margin-left: 8px !important;
      display: flex;
      align-items: center;
      max-width: unset !important;
      top: 2px !important;
    }

    // TODO: grid, rows, column, and span are used here...

    ion-item {
      flex: 0 0 auto;
    }

    app-formly-field-checkbox {
      width: inherit;
      justify-content: start;
      display: flex;
    }

    app-formly-field-checkbox div {
      justify-content: start;
      width: 100%;
    }
  }

  .checkbox-left {
    flex: 1;
  }

  // Title bar margins smaller
  .simple-section-container {
    .section-title-row {
      margin: 0px;
    }
  }

  .subsection-block {
    margin-right: 21px;
  }

  .internal-card-view {
    // background: lightcyan;   // For troubleshooting
    display: flex;
    padding: 10px 4px 10px 20px;
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    margin-bottom: 10px;

    .no-padding-bottom {
      display: flex;
      flex: 1;
    }
  }

  .formly-flex-container.internal-card-view {
    width: auto !important;
  }

  .internal-section {
    margin-bottom: 10px;
    margin-right: 20px;

    .simple-section-container {
      // padding-bottom: 10px;

      > ion-item {
        margin-left: 0px !important;
        margin-right: 20px !important;
        margin-bottom: 0px !important;
      }
    }

    // Internal card view inside internal section has additional margin
    .internal-card-view {
      margin-right: 20px;
    }

    .section-row {
      margin-bottom: 10px !important;
    }
  }

  // No title here - error icon should be pushed up
  app-error-info-icon {
    div.error-icon {
      top: var(--vrsk-rf-error-icon-top-shallow) !important;
    }
  }

  // End Exposures
}

// RR Occupants - FR
.fullrisk-editoccupant-hazard {
  .occupant-details {
    --ion-background-color: $white-color;
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    width: 97%;
    // padding-left: 19px;
    // padding-top: 5px;
    float: left;
    margin-top: 5px;
    clear: both;
    margin-bottom: 10px;
    margin-left: 20px;

    .simple-section-container {
      formly-field {
        width: 97.6%;

        .full-width {
          formly-field {
            width: 99.5%;
          }
        }

        app-summary-value {
          float: left;
          width: 97%;
          margin-right: 5px;
        }
      }
    }

    .summary-entry {
      // width: 158px;
      padding: 0;
      margin: 0 5px 0 0;
      height: 60px;

      ion-label {
        font-size: 11px;
        font-weight: 400;
        line-height: 12.89px;
        color: #000000;
        margin-top: 0;
        padding-top: 10px;
        margin-bottom: 6px;
      }
    }

    .box-container {
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      .simple-section-container {
        app-summary-value {
          //  float: left;
          margin-right: 5px;
        }

        .item-shadow {
          box-shadow: none;
          border-radius: 0;
        }
      }

      app-formly-simple-section-wrapper,
      .summary-row-container,
      app-formly-summary-row {
        width: 100%;
      }
    }

    .top-margin-l {
      margin-top: 15px !important;
      margin-bottom: 10px !important;
    }

    .flex-box {
      display: flex;
      width: 98%;
      flex-flow: column;
      margin-bottom: 3px;

      &.common-checkbox {
        // margin-right: 15px;
        // margin-top: 10px;
      }

      .item-select {
        .field-extended-select-container {
          display: flex;
          align-items: center;
          margin-left: auto;
          margin-right: 10px;
          position: relative;
          width: 114px;
        }
      }

      app-formly-field-select-checkbox {
        ion-item {
          width: 100%;
        }
      }
    }

    .occupant-details-container {
      ion-item {
        margin: 0;
        padding: 0;
        align-items: center;
        --min-height: 61px;

        // display: flex;
        app-formly-field-simple-select ion-item {
          display: flex;
        }

        .select-checkbox-container {
          display: flex;
          align-items: center;
          height: 61px;

          ion-label {
            display: none;
          }

          ion-select {
            min-width: 114px;
            opacity: 1;
          }

          .combustiblityclass-select {
            margin-right: 10px;
            width: var(--vrsk-field-width-m);
          }
        }

        .input-checkbox-container {
          width: 171px;
          display: flex;
          align-items: center;

          .csp-input {
            margin-right: 0;
          }

          ion-input,
          ion-select {
            min-width: 114px;
          }

          ion-label {
            display: none;
          }
        }

        .selectbox-container {
          display: flex;
          align-items: center;
          width: 247px;
          height: 61px;

          ion-select {
            min-width: 187px;
          }

          ion-label {
            display: none;
          }
        }
      }
    }

    .item-shadow {
      padding-bottom: 10px;
      box-shadow: none !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 0 !important;
    }

    .expand-collapse {
      margin-left: -5px;
      margin-top: 5px;
    }

    .section-collapse {
      transform: rotate(180deg);
    }
  }

  .occupant-card {
    padding: 10px 20px 20px 0px;

    .delete-btn {
      height: 100%;
      margin-top: 0px;
      --padding-end: 10px;
      float: left;
    }

    .occupanthazard-section {
      .bg2symbol-container {
        .selectbox-container {
          margin-right: 10px;
          width: 187px;

          ion-select {
            min-width: 187px;
          }
        }
      }

      margin-top: 8px;

      formly-field {
        float: left;
        white-space: nowrap;

        &.occupant-doingbusiness {
          float: left;
          white-space: nowrap;
          margin-left: 5px;

          ion-input {
            width: 238px;
          }
        }

        &.occupant-suiteno {
          max-width: 70px;

          ion-input {
            width: 62px;
          }
        }

        &.occupant-Scheduleno {
         // float: right;
         position: absolute;
         right: 20px;
          ion-searchbar {
            width: 238px;
          }
        }

        &.common-alignment {
          margin-left: 62px;
          margin-top: 20px;
          width: 92.8%;
        }

        .question-wrapper-item {
          height: 61px;
          margin-left: 3px !important;
          box-shadow: var(--vrsk-box-shadow3) !important;
        }

        .susceptibility-container .question-wrapper-item {
          .susceptibility-class {
            width: var(--vrsk-field-width-m);
          }

          .input-checkbox-container {
            width: var(--vrsk-field-width-m);
            margin-right: 10px;
          }

          ion-select {
            min-width: var(--vrsk-field-width-m);
          }
        }

        ion-note {
          float: right;
          font-size: 11px;
          line-height: 12.89px;
          color: var(--vrsk-dark-gray-color);
        }
      }
    }
  }

  .coverage-field {
    .field-style2 {
      height: 60px;
      margin-right: 20px;
      // margin-left: 0px;

      ion-item {
        width: 238px;
      }

      ion-label {
        margin-top: 4px;
      }
    }
  }

  .remove-icon {
    height: var(--vrsk-remove-icon-height);
    width: var(--vrsk-remove-icon-width);
    color: var(--vrsk-remove-icon-color);
  }

  // for levels
  .occupant-levels-fields {
    display: block !important;
    width: 100%;

    .select-sm-width {
      width: 124px;
      position: relative;
      z-index: 999;

      ion-select {
        max-width: 100%;
      }
    }

    .newLine-field {
      clear: both;
    }

    .checkbox-level {
      padding-top: 24px;

      .grid-style .checkbox-row {
        .checkbox-label {
          top: 8px !important;
        }
      }
    }

    .padding-btm-md {
      padding-bottom: 20px;
    }
  }

  //TODO : occupant hazards custom css for section warpper
  .occupant-hazards-wrapper {
    width: 100%;

    app-formly-simple-section-wrapper {
      ion-item {
        box-shadow: none !important;
        margin: 15px 3px 0px 0px !important;

        ion-grid ion-row ion-col {
          app-formly-building-hazards {
            ion-grid {
              margin-left: -10px !important;
              margin-right: 0px !important;

              ion-icon {
                margin-bottom: 2px;
                margin-right: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}

// Hydraulic section
.hydraulic-density-container {
  .hydraulic-density-select {
    app-formly-field-simple-select {
      ion-item {
        --inner-padding-end: 0px;
        --padding-start: 0px;
      }

      ion-select {
        width: 114px !important;
      }

      .selector {
        --padding-start: 0px !important;
      }

      .card-style {
        padding-top: 7px !important;
      }
    }
  }

  .hydraulic-density-input {
    .unit-suffix {
      font-size: 13px !important;
      margin-left: -6px !important;
    }

    .field-extended-input-container {
      .field-input-wrapper ion-input {
        margin-left: 5px !important;
      }

      .right-align {
        margin-right: 1px;
      }

      .field-item-wrapper {
        padding-top: 3px;
      }
    }
  }
}

// SR main drain testing styling
.main-drain-testing-container {
  .date-width {
    .date-input-wrapper {
      width: 114px !important;
    }
  }
}

.two-inch-drain-tests-container {
  .field-container {
    margin-right: 0px !important;
  }

  .input-width-xs {
    width: 94px;
  }

  .static-psi-width {
    width: 152px;
  }

  .residual-psi-width {
    width: 174px;
  }

  .test-conducted-by-width {
    width: 308px;
  }
}

.two-inch-drain-grid-view-container {
  app-formly-simple-section-wrapper {
    .simple-section-container {
      ion-item.item.main-section-item {
        ion-row.section-title-row {
          padding-right: 20px;
        }

        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
  }
}

// WS SC system component style
.system-component-container {
  .wrapper-title {
    app-formly-question-wrapper {
      ion-item.question-wrapper-item {
        margin-right: 24px !important;
        margin-bottom: -3px;
      }
    }

    // TODO: To be Removed
    // app-formly-question-wrapper {
    //   ion-item.question-wrapper-item {
    //     // margin-left: 3px !important;
    //     // margin-right: 24px !important;
    //     --padding-start: 13px;
    //     margin-top: 0px;
    //     margin-bottom: 5px;
    //     height: 60px !important;

    //     ion-grid {
    //       ion-row {
    //         ion-col {
    //           ion-item {
    //             margin-right: -1px !important;
    //           }
    //         }

    //         ion-col.container-styling {
    //           margin-bottom: 7px;
    //           margin-top: 4px;
    //         }
    //       }
    //     }
    //   }
    // }

    ion-item-sliding {
      app-formly-field-checkbox {
        ion-checkbox {
          margin-right: 0px !important;
          height: 41px;
          width: 41px;
        }
      }
    }
  }

  .misc-items-layout {
    app-formly-question-wrapper {
      ion-item.question-wrapper-ion-item {
        --padding-end: 0px;
      }
    }
  }

  .input-checkbox-container {
    display: flex;
    align-items: center;
  }

  .custom-checkbox {
    app-formly-field-checkbox {
      ion-checkbox {
        height: 41px;
        width: 41px;
      }
    }
  }

  .simple-checkbox {
    app-formly-field-checkbox {
      ion-checkbox {
        height: 41px;
        width: 41px;
        margin-right: 5px !important;
        margin-bottom: 10px;
      }
    }
  }

  .checkbox-input {
    app-formly-field-simple-input {
      ion-input {
        width: 114px;
      }
    }
  }

  .sp-schedule-wrapper {
    app-formly-simple-section-wrapper {
      .simple-section-container .main-section-item {
        --padding-start: 13px;
        margin-right: 25px;
      }
    }

    // TODO: To be Removed
    // app-formly-simple-section-wrapper {
    //   ion-item {
    //     margin-left: 5px !important;
    //     margin-right: 24px !important;
    //     --padding-start: 14px;
    //     margin-top: 5px !important;
    //     --inner-padding-bottom: 0px !important;

    //     ion-grid {
    //       ion-row:nth-child(2) {
    //         margin-bottom: 10px;
    //       }
    //     }
    //   }

    //   app-title {
    //     ion-label {
    //       font-size: 17px;
    //       font-weight: 400;
    //     }
    //   }
    // }
  }

  .simple-section-container ion-item ion-grid ion-row.padding-bottom {
    margin-right: -20px !important;
  }

  // TODO: To be Removed
  // .overhead-section {
  //   app-formly-simple-section-wrapper {
  //     ion-item {
  //       --inner-padding-bottom: 8px;
  //     }
  //   }
  // }

  .radio-group {
    app-formly-question-wrapper {
      // TODO: To be Removed
      // ion-item {
      //   margin-right: 18px !important;

      //   ion-grid {
      //     ion-row {
      //       ion-col {
      //         ion-list {
      //           margin-right: -20px !important;
      //           display: inline-block;
      //         }
      //       }

      //       ion-col.padding-right {
      //         right: 6px;
      //         bottom: 3px;
      //       }

      //       ion-col.container-styling {
      //         margin-bottom: 7px;
      //         margin-top: 4px;
      //         z-index: 999;
      //       }
      //     }
      //   }

      //   ion-item-sliding {
      //     ion-row {
      //       ion-col.padding-right {
      //         right: 0px;
      //         bottom: 3px;
      //       }
      //     }
      //   }
      // }
      ion-item.question-wrapper-item {
        margin-right: 15px;
        margin-bottom: -3px;
      }

      app-formly-field-radio {
        ion-grid.prop-radio-container {
          margin: 0px;
          padding: 0px;
        }

        // .prop-radio-container .radio-circles-block {
        //   margin-inline-start: 25px;
        // }
      }
    }

    // TODO: To be Removed
    // app-formly-field-radio {
    //   ion-grid {
    //     margin-left: -10px;
    //     margin-right: 0px;
    //     margin-bottom: 0px !important;
    //   }

    //   ion-radio-group {
    //     ion-label {
    //       display: none;
    //     }

    //     ion-item {
    //       box-shadow: none !important;
    //       margin-right: -18px !important;
    //       padding-top: 0px !important;
    //       --inner-padding-top: 0px;
    //       margin-top: -7px !important;
    //     }
    //   }
    // }
  }

  .radio-toggle-yes-no {
    box-shadow: 0px 4px 8px 0px #0000001f;
    flex-direction: column;
    display: flex;
    margin-right: 25px;
    margin-bottom: 5px;
  }

  .radio-group-toggle {
    app-formly-field-radio {
      ion-grid {
        margin-right: 0px;
        padding: 0;
        margin-left: -4px;
        margin-bottom: -5px !important;
      }

      ion-item {
        box-shadow: none !important;
        height: 60px !important;
        margin: 3px;
        border-radius: 0px;
        padding-top: 0px;
        --padding-start: 10px;
        --inner-padding-end: 0px;
        margin-right: -4px !important;
      }
    }
  }

  .yes-no-wrapper {
    margin-bottom: 10px;

    app-formly-question-wrapper {
      ion-item {
        margin-left: 20px !important;
        margin-right: 20px !important;
        margin-top: -5px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    app-formly-field-simple-input {
      div.field-extended-input-container {
        margin-left: 20px !important;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }

    app-title {
      ion-label {
        span {
          display: none;
        }
      }
    }
  }

  .area-input {
    app-formly-field-simple-input {
      div.field-extended-input-container {
        margin-right: 24px;
        border-radius: 0px;
      }
    }
  }

  .yes-no-toggle {
    app-formly-field-yes-no {
      div.field-container {
        box-shadow: none !important;
        margin-top: 10px;
      }
    }

    .question-wrapper-container {
      .field-segment-button {
        margin-right: -10px !important;
      }
    }
  }

  .automatic-sprinklers-container {
    .custom-margin-bottom {
      app-formly-question-wrapper {
        ion-item.question-wrapper-item {
          margin-bottom: -5px !important;
        }
      }
    }
  }
}

// Enhanced Wind
.enhanced-wind-group-container {
  > app-formly-simple-section-wrapper > .simple-section-container > .main-section-item {
    --padding-start: 15px;
    padding: 15px 0px 5px;
  }

  .narrow-section-wrapper {
    .section-title-row,
    .section-field-col {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      height: 65px;
    }
  }

  .no-padding {
    padding: 0;
  }

  app-error-info-icon {
    div.error-icon {
      top: -50px !important;
    }
  }

  formly-group formly-field:nth-child(2) {
    ion-col.padding-right > ion-item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  div > ion-item > ion-grid > ion-row.section-title-row {
    margin-top: 0px !important;
    // margin-bottom: 0px !important;
  }

  .item-has-value:has(.formly-flex-container.full-width) {
    margin-right: 0;
  }

  ion-item.item-shadow-extended-input::part(native) {
    padding-left: 20px;
  }

  .label-weight div {
    padding-left: 5px;
    margin: 10px 0;
  }

  app-remove-icon ion-button {
    height: 100%;
    padding-top: 10px;
  }

  app-formly-simple-section-wrapper app-title ion-label.section-label {
    font-size: 17px;
    font-weight: 400;
  }

  .formly-flex-container {
    .radio-group {
      padding-right: 0;
      padding-bottom: 0;
    }

    .prop-radio-container {
      margin-right: 0;
      padding: 0;
    }

    .prop-radio-container > ion-row > ion-col {
      padding: 0;
      padding-right: 10px;
    }

    .prop-radio-container ion-radio-group > div ion-item:last-child ion-label {
      margin-right: 0;
    }

    ion-item::part(native) {
      padding-left: 10px;
    }

    ion-item {
      --inner-padding-end: 0;
    }

    .field-input-block {
      input {
        width: 100px;
      }

      .field-unit {
        color: var(--vrsk-dark-gray-color);
      }
    }
  }


  .swipeable-card-container ion-row {
    box-shadow: 0px 4px 8px 0px #0000001f;
    margin: 5px 0 5px 5px !important;
    padding: 0 !important;
  }

  .swiper-checkbox-wrapper {
    ion-row.section-title-row {
      padding-right: 10px !important;
    }

    .swiper-checkbox-question {
      // ion-item-sliding > ion-row > ion-col.right-align > ion-item {
      //   margin-right: 0;

      //   .checkbox-row {
      //     margin-right: 4px !important;
      //   }
      // }
    }
  }

  formly-field.question-wrapper-container > .question-wrapper-item,
  .item-shadow-extended-input,
  formly-group .item-shadow {
    margin-left: 5px !important;
    margin-right: 15px !important;
    margin-bottom: 3px !important;
    margin-top: 0px !important;
  }

  .swiper-checkbox-wrapper.narrow-section-wrapper {
    .question-wrapper-item {
      margin-right: 15px !important;
      margin-left: 5px !important;
    }

    .swiper-starting-padding {
      ion-item.question-wrapper-item {
        --padding-start: 20px;
      }
    }

    // TODO: To be Removed
    // formly-group formly-field:last-child ion-item {
    //   margin-bottom: 20px;
    // }
  }

  // Box shadow for card item
  .generic-array-container .swipeable-card-container {
    margin: 5px;
    box-shadow: var(--vrsk-box-shadow3);
  }

  .flex-array-contanier {
    formly-group {
      display: flex;
      flex-wrap: wrap;

      .width-30 {
        width: 30%;
      }

      .width-auto {
        flex: 1;

        .field-input-container {
          margin-right: 0;
        }
      }
    }
  }
}

.enhanced-simple-section-container {
  .main-section-item {
    --padding-start: 20px;
  }

  .question-wrapper-item {
    margin-left: 5px !important;
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  formly-group formly-field:last-child ion-item {
    margin-bottom: 20px;
  }
}

// RR W Fire-resistive walls styling
.fire-resistive-container {
  app-formly-field-yes-no {
    .field-container {
      margin-bottom: 10px;
    }
  }

  .fire-resistive-list-type {
    padding-bottom: 0px;
  }
}

// WR Environment and Exposures
.wr-environment-exposures-container,
.wr-additional-roof-container,
.wr-framework-container,
.wr-wall-container {
  margin-left: -25px;

  .right-padding {
    app-formly-question-wrapper {
      ion-grid.padding-right {
        padding-right: 10px;
      }
    }
  }

  .item-select-field {
    width: 238px;
  }

  .card-style {
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 3px !important;
    box-shadow: var(--vrsk-box-shadow5) !important;
    margin-bottom: 0px !important;
  }

  .field-extended-select-container {
    justify-content: space-between !important;
    padding-left: 20px;
    padding-top: 4px !important; // Pending better solution
    padding-bottom: 3px !important;

    .card-style {
      padding-top: 0px;
    }
  }

  .question-wrapper-container {
    .right-align {
      align-items: center;
    }

    .item {
      min-height: 5px;
      // TODO: To be Removed
      // margin-bottom: 2px;
      // margin-top: 3px;
      // margin-right: 5px;
    }

    .section-label {
      font-weight: 400;
    }
  }

  .exposure-yes-no-height {
    .question-wrapper-row {
      height: 84px;
    }
  }

  .select-full-width {
    margin-right: 20px;
    //  max-height: 60px;  --> make default for QW inside SW
    display: contents;

    .item-shadow {
      height: 60px;
    }

    ion-select {
      width: 238px;
    }
  }
}

.wr-additional-roof-container,
.wr-wall-container {
  margin: 0;

  .grey-rippler {
    ion-radio.radio-checked::part(container) {
      background-color: var(--vrsk-dark-blue) !important;
    }
  }

  // radio group wrapper
  .radio-group-wrapper {
    .item-shadow,
    ion-col,
    ion-col.right-align > ion-item {
      height: 60px !important;
    }

    ion-grid {
      padding-top: 0;
      padding-bottom: 0;
      margin-left: 0;
    }

    ion-radio-group {
      padding-top: 0;
      padding-bottom: 0;
    }

    .formly-flex-container {
      .radio-group {
        padding-right: 0;
        padding-bottom: 0;
      }

      .prop-radio-container {
        margin-right: 0;
        padding-right: 0;
      }

      .prop-radio-container > ion-row > ion-col {
        padding: 0;
      }

      .prop-radio-container ion-radio-group > div ion-item:last-child ion-label {
        margin-right: 0;
      }

      ion-item::part(native) {
        padding-left: 0;
      }

      ion-item {
        --inner-padding-end: 0;
      }

      .field-input-block {
        width: 130px;
      }
    }
  }

  .question-wrapper-container .question-wrapper-item {
    --padding-start: 15px;
    border-bottom-right-radius: 3px;
    margin: 3px 20px 0 0 !important;
  }

  .horizontal-field-group {
    padding-right: 10px;
  }

  // Right align for ion-select with question wrapper
  // With swipe
  // TODO: To be Removed
  // .question-wrapper-item-sliding {
  //   .item-select {
  //     margin-right: 2px;
  //   }
  // }

  // Without swipe
  .item-select {
    margin-right: 4px;
  }
}

// Wall envelope additional feature section
.wr-additional-wall-container {
  .select-full-width {
    app-formly-question-wrapper {
      .question-wrapper-container {
        ion-item.question-wrapper-ion-item {
          --padding-end: 0px;
        }
        .item-shadow {
          height: 60px;
        }
        .question-wrapper-item {
          margin-top: 3px;
          margin-bottom: 0px;
          margin-right: 20px;

          .question-wrapper-value > .item {
            //General class override so important required
            margin-right: 10px !important;
          }
        }
      }
    }
  }

  .evidence-wall-envelope {
    app-formly-question-wrapper {
      .question-wrapper-container {
        .question-wrapper-item {
          margin-top: 3px;
          margin-bottom: 0px;
          margin-right: 20px;

          .question-wrapper-value > .item {
            //General class override so important required
            margin-right: 5px !important;
          }
        }
      }
    }
  }

  .sign-architectutal-yes-or-no {
    app-formly-question-wrapper {
      .question-wrapper-container {
        .question-wrapper-item {
          margin-top: 3px;
          margin-bottom: 0px;
          margin-right: 20px;
        }

        .field-container {
          padding-right: 0px;
        }
      }
    }

    .field-segment-button {
      margin-right: 5px !important;
    }
  }
}

// WR wall envelope section
.wr-wall-container {
  .question-wrapper-container {
    margin-left: 5px;
  }

  .padding-bottom-20 {
    padding-left: 0;
  }

  .simple-section-container {
    > .item {
      --padding-start: 15px;
    }
    .section-title-row .section-title-col {
      margin-left: 5px;
    }

    .question-wrapper-container .question-wrapper-item {
      margin-right: 15px !important;
    }
  }
}

// WR Roof Envelope
.wr-roof-envelop-section-container {
  .roof-envelope-predominant {
    .checkbox-list-container {
      --padding-start: 0px !important;

      //padding-bottom: 20px;
      ion-grid {
        padding: 0px;
      }

      .radio-options {
        margin-bottom: 3px;
        margin-top: 0px;
        margin-left: 3px;
        margin-right: 3px;
        box-shadow: var(--vrsk-box-shadow5);
      }

      ion-grid form {
        padding-bottom: 10px;
        padding-right: 10px;
      }
    }
  }
}

.wr-roof-envelop-feature-section-container {
  .question-wrapper-item {
    margin-top: 3px !important;
    margin-right: 20px !important;
  }

  .question-wrapper-container .item-select-field {
    margin-bottom: 1px !important;
    margin-top: 1px !important;
  }

  .select-dropdown {
    .question-wrapper-container .item-shadow {
      margin-top: 3px;
      margin-bottom: 0px;
      height: 60px;
      border-radius: 3px;
    }

    .question-wrapper-value {
      .item {
        margin-right: 2px;
      }

      ion-select {
        width: 238px;
      }
    }
  }

  .select-full-width {
    .question-wrapper-value .item {
      margin-right: 5px;
    }

    display: contents;

    .item-shadow {
      height: 60px;
      margin-top: 3px;
      margin-bottom: 0px;
      border-radius: 3px;
    }

    ion-select {
      width: 238px;
    }
  }

  .skylights-yes-no-height {
    .question-wrapper-container .item-shadow {
      margin-top: 3px;
      margin-bottom: 0px;
      height: 60px;
      border-radius: 3px;
    }

    .question-wrapper-value .item {
      margin-right: 0px;
    }
  }
}

.wr-framework-container,
.wr-wall-container {
  app-formly-field-predominant-checkbox {
    ion-item.checkbox-list-container {
      --padding-start: 0;
    }

    ion-grid {
      padding: 0;
    }
  }

  // Vertical space between items
  .checkbox-list-container .radio-options {
    margin-bottom: 3px;
    margin-top: 0px;
  }
}

//Light frame construction styles
.light-frame-container {
  .percent-input-width {
    padding: 0px 5px 0px 0px;
    width: var(--vrsk-field-width-m);
  }

  .align-items-center {
    align-items: center;
  }

  // TODO: To be Removed
  // .roof-percent-style {
  //   padding-right: var(--vrsk-field-padding-s);
  //   padding-bottom: var(--vrsk-field-padding-s);

  //   app-formly-question-wrapper {
  //     .item-shadow {
  //       margin: 0px 20px 20px 2px;
  //     }

  //     .item {
  //       margin: 0px 0px 0px 0px;
  //     }

  //     .item-shadow {
  //       margin-left: 2px;
  //       box-shadow: var(--vrsk-box-shadow3);
  //       border-radius: var(--vrsk-border-radius);
  //       margin-right: var(--vrsk-field-padding-sm);
  //       --padding-start: 12px;
  //       margin-bottom: var(--vrsk-field-padding-sm);
  //     }

  //     .formly-flex-container {
  //       formly-field {
  //         padding-bottom: var(--vrsk-field-padding-s);
  //       }
  //     }
  //   }

  //   .roof-percent-input {
  //     padding: 10px 0px 0px 0px;
  //     width: var(--vrsk-field-width-ml);
  //   }
  // }

  .na-width {
    padding-top: 15px;
    padding-right: var(--vrsk-field-padding-sm);
  }

  .checkbox-style {
    padding-right: var(--vrsk-field-padding-s);
    padding-bottom: 0px;

    app-formly-field-checkbox {
      .checkbox-row {
        margin-right: 3px !important;
      }
    }
  }

  // TODO: To be Removed
  // .floor-percent-style {
  //   padding-right: var(--vrsk-field-padding-s);
  //   padding-bottom: var(--vrsk-field-padding-s);
  //   width: var(--vrsk-field-width-ml);

  //   .formly-flex-container {
  //     formly-field {
  //       padding-bottom: var(--vrsk-field-padding-s);
  //     }
  //   }

  //   .floor-percent-input {
  //     padding: 10px 5px 0px 0px;
  //     width: var(--vrsk-field-width-m);
  //   }
  // }
}

// system component sprinkler table css
.sprinkler-table-container {
  div {
    ion-card {
      app-swipeable-card {
        ion-item-sliding {
          app-remove-icon {
            ion-button {
              margin-top: -10px;
              margin-left: -2px;
            }
          }
        }
      }
    }
  }

  .sprinkler-table-field-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3px;
    margin-left: -3px;
    padding-bottom: 15px;

    .system-number-input {
      padding-bottom: 20px;

      ion-input {
        width: 57px;
        margin-top: 3px;
      }
    }

    .make-model-input {
      ion-input {
        width: 171px;
        margin-top: 3px;
      }
    }

    .medium-input {
      ion-input {
        width: var(--vrsk-field-width-m);
        margin-top: 3px;
      }
    }

    .medium-input-row-second {
      ion-input {
        width: var(--vrsk-field-width-m);
        margin-top: 3px;
      }
    }
  }
}

// sprinkler table grid view
.sprinkler-table-grid-view {
  app-formly-simple-section-wrapper div {
    margin-right: 10px;
    margin-left: 5px;
  }

  app-formly-common-custom-grid-view ion-grid {
    margin: -8px 20px 12px 0px;
  }
}

// Styles for Floors roof bg2
.bgII-container {
  display: block;
  padding: 0px 20px 10px 2px;
  // TODO: To be Removed
  // box-shadow: var(--vrsk-box-shadow3);
  // border-radius: var(--vrsk-border-radius);

  .row-gap {
    margin-bottom: 3px;
  }

  .checkbox-full-width {
    width: 100%;
    display: flex;

    app-formly-field-checkbox {
      width: inherit;
    }

    ion-row {
      width: 100%;
      display: block;
    }

    ion-checkbox {
      margin-right: var(--vrsk-field-label-height-s) !important;
      margin-top: var(--vrsk-field-label-height-s);
      --size: var(--vrsk-checkbox-size-m) !important;
    }

    .checkbox-label {
      margin-left: var(--vrsk-field-padding-s) !important;
      display: flex;
      align-items: center;
      max-width: unset !important;
      top: 2px !important;
    }
  }

  .card-view {
    display: flex;
    padding: 0px 0px 0px 10px;
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
  }

  .radio-label-width {
    .prop-radio-container {
      margin-bottom: var(--vrsk-field-padding-s);

      ion-col {
        padding-bottom: var(--vrsk-field-padding-s) !important;
        padding-left: var(--vrsk-field-padding-s) !important;
      }
    }

    ion-radio-group {
      .horizontal-radio-div {
        ion-lebel {
          max-width: 158px !important;
        }
      }
    }
  }

  .wind-uplift {
    display: block !important;
    padding-left: var(--vrsk-field-label-height-s);
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    margin-bottom: 3px;
  }

  .skylight-style {
    display: block !important;
    padding-right: var(--vrsk-field-padding-sm);
    padding-left: var(--vrsk-field-label-height-s);
    padding-bottom: var(--vrsk-field-padding-sm);

    .prop-radio-container {
      padding: 0px !important;
      margin: 0px !important;

      ion-radio-group {
        .horizontal-radio-div {
          margin-left: 0px;
        }
      }

      ion-col {
        padding: 0px;
      }
    }
  }

  ion-radio-group {
    .horizontal-radio-div {
      .horizontal-item {
        --padding-start: 0px;
      }

      ion-label {
        max-width: 176px !important;
      }
    }
  }
}

// RR SC Effective area secondary construction
.effective-area-container {
  // TODO: find a way to override simple-section styling for card padding
  padding-bottom: 10px !important;

  .effective-area-style {
    ion-input {
      width: 149px;
    }

    ion-select {
      width: 141px;
    }

    .otherFloor-styling {
      display: flex;

      .text-effective-area {
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 10px;

        // .value-style {
        //   top: 10px;
        // }
      }

      .text-effective-area.text-or {
        font-size: 15px;
        color: #999;
      }
    }

    ion-item {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

// Pipe Schedule
.pipe-schedule-container {
  .remove-padding {
    app-formly-field-radio {
      .prop-radio-container {
        ion-radio-group {
          .horizontal-radio-div {
            .radio-background-circle {
              margin-left: 7px !important;
            }
          }
        }
      }
    }
  }

  .left-padding-radio {
    .question-wrapper-value {
      .item-alignment-extended-input {
        --padding-start: 0px;
        margin-left: 0px;
      }
      .prop-radio-container {
        .horizontal-radio-div {
          ion-item.horizontal-item {
            --padding-start: 0px;
            .radio-btn-label {
              margin-right: 0px;
            }
            .radio-foreground-circle {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .requirement-style {
    ion-label {
      font-weight: 400 !important;
      white-space: nowrap !important;
    }

    .question-wrapper-value {
      .item {
        margin: 0px;
      }
    }

    .radio-input-style {
      display: flex;

      .radio-style {
        max-width: 80px;

        .prop-radio-container {
          padding: 0px;
          margin: 0px;

          ion-col {
            padding: 0px;
          }
        }

        .radio-background-circle {
          margin-left: 7px !important;
        }
      }

      .input-style {
        max-height: 41px;
        padding-top: 6px;
        max-width: 114px;
      }
    }
  }
  .dpv-system {
    .prop-radio-container {
      padding-right: 0px;
    }
  }

  .combustible-width {
    .question-wrapper-title > div {
      width: 220px;
    }
  }

  .adjustment-style {
      .question-wrapper-container {
        ion-item.question-wrapper-item {
          margin: 3px 20px;
        }
      }
    }
  
  .unadjusted-sprinkler-bottom-style {
      .question-wrapper-container {
        ion-item.question-wrapper-item {
          margin-bottom: 20px;
        }
      }
    }
  .adjustment-left-padding {
      app-formly-simple-section-wrapper {
        ion-item::part(native) {
          padding-left: 0px;
        }
      }
    }
  
  .adjustment-label {
      app-formly-simple-section-wrapper app-title ion-label.section-label {
        padding: 20px;
      }
    }
  .radio-btn-margin{
      .prop-radio-container .horizontal-field-group .radio-circles-block ion-radio.radio-foreground-circle {
        margin-right: 0px;
        margin-inline-end:0px;
      }
    }
  .adjustment-requirement-container .prop-radio-container .horizontal-radio-div ion-label {
    margin-right: 0px;
    }
   
}

// Building name container
.building-name-container {
  .addition-fields {
    .flex-grid-container {
      .row > div:nth-child(3) {
        justify-content: end;
      }
    }
  }

  .simple-section-container .question-wrapper-container .question-wrapper-row .question-wrapper-value > .item {
    margin-right: 10px;
  }

  app-formly-question-wrapper {
    ion-item.question-wrapper-ion-item {
      --padding-end: 16px;
    }
  }
}

.automatic-sprinklers-container {
  .input-width-m {
    .field-container {
      width: 114px;
    }
  }
}

// Overage Sprinkler Test Container
.overage-sprinkler-test-container {
  .simple-section-container {
    .main-section-item {
      ion-row.section-title-row {
        padding-right: 20px;
      }

      ion-row.section-content-row {
        margin-right: 15px;
      }
    }
  }
}

//dry pipe valve testing container
.dry-pipe-valve-test-container {
  .dry-pipe-section {
    app-formly-question-wrapper {
      .question-wrapper-container {
        .question-wrapper-item {
          margin-top: 3px;
          margin-right: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .internal-exam {
    app-formly-simple-section-wrapper {
      .simple-section-container {
        .main-section-item {
          margin-right: 0px;
        }
      }
    }
  }

  .date-width {
    .padding-right-0 {
      input {
        padding-right: 0px;
      }
    }
  }

  .dry-pipe-valve-testing {
    .question-wrapper-container {
      .question-wrapper-value > .item {
        padding-right: 10px;
      }
    }
  }
  .simple-section-container .padding-right-20{
    padding-right: 20px !important;
  }
}

//dry pipe valve trip testing container
.dry-pipe-valve-trip-test-container {
  .simple-section-container {
    ion-item.item.main-section-item {
      margin-right: 0;
    }
  }
}

//internal-sprinkler-pipe-container
.internal-sprinkler-pipe-container {
  app-formly-simple-section-wrapper {
    .simple-section-container {
      ion-item.item.main-section-item {
        ion-row.section-title-row {
          padding-right: 20px;
        }
        ion-row.section-content-row {
          margin-right: 15px;
        }

        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
  }
}

.internal-pipe-condition-container {
  .test-conducted-input {
    width: 310px;
  }
  .system-number-input {
    ion-input {
      width: 57px;
    }
  }
  .area-affected-input {
    width: 125px;
  }
  .action-taken-input {
    width: 160px;
  }
  .date-width app-formly-field-date-picker {
    .date-picker-container .picker-wrapper {
      width: 150px;
    }
  }
}
//dry pipe valve trip testing container
.dry-pipe-valve-test-container {
  .simple-section-container {
    .main-section-item {
      ion-row.section-title-row {
        padding-right: 20px;
      }

      ion-row.section-content-row {
        margin-right: 15px;
      }
    }
  }
}

.internal-protection-container {
  .automatic-fire-alarm-detection-system {
    ion-item.swiper-item {
      --padding-start: 13px;
    }

    .select-width {
      app-formly-field-simple-select {
        margin-right: 10px;

        ion-item {
          width: 238px;
        }

        ion-select {
          width: 100%;
        }
      }
    }
  }
  .display-flex {
    display: flex;
  }
  .checkbox-label {
    app-formly-field-checkbox {
      .grid-style .checkbox-label {
        display: block;
      }
    }
  }
  .watchman-type-field-style {
    width: 317px;
    ion-select {
      width: 100%;
    }
  }
  .internal-protection {
    app-formly-question-wrapper {
      .question-wrapper-container {
        ion-item.question-wrapper-ion-item {
          margin-top: 0px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .limited-supply-container {
    max-height: 41px;
    app-formly-question-wrapper {
      ion-item.question-wrapper-ion-item {
        --padding-end: 10px;
      }
    }

    .input-style {
      padding: 0px;
      max-width: 114px;
      margin-right: 10px;
    }

    .select-style {
      width: 238px;
      padding: 0px;
    }
  }
  // internal protection sprinkler section style
  .internal-protection-sprinkler-container {
    box-shadow: var(--vrsk-box-shadow3);
    border-radius: var(--vrsk-border-radius);
    width: 97%;
    float: left;
    clear: both;
    padding: 15px 5px 10px 15px;
    margin: 5px 5px 5px 5px;

    .version-style {
      sup {
        color: #168bf6;
        font-weight: 500;
      }

      span {
        padding-left: 6px;
        font-weight: 500;
      }
    }

    .partial-group-style {
      display: flex;
      flex-wrap: wrap;
      box-shadow: var(--vrsk-box-shadow3);
      border-radius: var(--vrsk-border-radius);
      margin: 5px 14px 5px 5px;
      padding: 10px 10px 10px 10px;

      .select-width {
        div.field-vertical-select-container{
          width: 214px;
        }
      }

      .input-style {
        width: 124px;
        margin-left: auto;
        margin-top: 20px;
      }

      .new-row {
        padding-top: 10px;
      }

      .station-type-width {
        div.field-vertical-select-container{
          width: 200px;
        }
       
      }

      .station-alarm-width {
        margin-left: auto;
        div.field-vertical-select-container{
          width: 214px;
        }
      }

      .checkbox1-width {
        width: 260px;
      }

      .checkbox2-width {
        width: 305px;
      }
    }
  }
}

//Firction Loss container
.friction-loss-container {
  .question-wrapper-item {
    app-formly-question-wrapper {
      ion-item.question-wrapper-item {
        --padding-end: 10px;
        --padding-start: 16px;
        height: 60px;
        --inner-padding-bottom: 8px;
      }

      ion-grid {
        ion-col {
          height: 60px;
          align-items: center;
        }
      }
    }
  }

  .select-friction {
    app-formly-question-wrapper {
      ion-item.question-wrapper-item {
        --padding-end: 20px;
        --padding-start: 18px;
      }
    }
  }

  .fitting-valve-style {
    app-formly-common-flex-grid {
      div.flex-grid-container {
        margin-left: 2px;
        margin-right: 8px;
      }

      div.col {
        flex: 0 0 auto !important;
      }
    }

    .fitting-select {
      margin-left: -7px;
    }

    .count-input {
      ion-item.field-item-wrapper {
        --padding-start: 10px;
      }
    }
  }
}

.friction-loss-grid-view-container {
  app-formly-common-custom-grid-view {
    ion-grid {
      padding-left: 1px;

      ion-col:first-child {
        flex: 0 0 auto !important;
        width: 22px !important;

        ion-button {
          margin-left: -8px;
        }
      }
    }
  }
}

.evidence-of-fire-sprinkler {
  app-formly-question-wrapper {
    ion-item.question-wrapper-ion-item {
      --padding-start: 20px;
    }
  }
}

//combustible fisnish Secondary construction
.combustible-finish-container {

  .simple-section-container .item.main-section-item .section-title-row .top-margin-l.section-title-col {
    margin-bottom: 0px;
}
.combustible-finish-cont .combustible-finish-style .interior-finish-container .simple-section-container ion-item {
  margin-top: 0px;
}

  .combustibleCheckbox {
    padding-top: 12px;
  }

  .text-combustible-finish.text-or {
    font-size: 15px;
    color: #999;
    padding-top: 32px;
  }

  .group-style {
    padding-left: 15px;
    margin-bottom: 10px;
    padding-top: 10px;
    display: flex;
    box-shadow:
      3px 4px 3px #e1e1e1,
      -3px 0px 3px #e1e1e1;
    border-radius: 3px;
    margin-right: 20px;
    min-height: 84px;
  }
  .formly-flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 97%;
  }
}

.hydraulic-data-design-container {
  .simple-section-container .item.main-section-item .default-content-style {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

// unused sprinklered area from sprinklere report
.unused-area-container {
  .formly-flex-container {
    display: flex;
    align-items: center;
    margin-right: 5px;
    width: auto;
    padding: 20px 15px 20px 20px;
    box-shadow: 0px 2px 10px 0px #00000026;

    formly-field {
      margin-top: 7px;
    }

    .new-row {
      margin-top: 0px !important;
    }

    .row-label {
      display: flex;
      flex-grow: 1;
    }

    .first-row {
      padding-bottom: 0px;
      width: 108px;
      margin-bottom: -4px;
    }

    .first-checkbox {
      app-formly-field-checkbox {
        ion-checkbox {
          width: 41px;
          height: 41px;
          margin: 0px;
        }
      }
    }

    div.field-input-container {
      ion-input {
        width: 99px;

        input {
          padding-right: 0px;
        }
      }
    }
  }
}

// Water Supply spoc two col layout container
.spoc-two-col-layout-container {
  .simple-section-container {
    .main-section-item {
      ion-row.row-padding-bottom {
        padding-right: 10px;
      }
    }
  }
}

//Wind Loss History Section
.wind-loss-history-container {
  flex-direction: column;

  .wind-loss-history-date {
    padding-bottom: 20px;

    app-formly-field-date-picker {
      .date-picker-container .section-label {
        padding-bottom: 0px;
      }
    }
  }

  .wind-loss-description {
    padding-right: 20px;
    padding-bottom: 20px;

    .textarea-wrapper {
      textarea {
        padding: 10px;
      }
    }

    .field-text-area-container {
      display: flex;
      flex-direction: column;
    }
  }

  .simple-section-container {
    .item ion-row .default-content-style {
      margin-bottom: 20px;
    }

    app-formly-common-custom-grid-view {
      .ion-grid-container {
        ion-row {
          margin-right: 20px;
        }

        .ion-align-items-center ion-label {
          height: 20px;
        }
      }
    }
  }
}

.wind-loss-test {
  .card-container .card-wrapper .ios {
    app-swipeable-card .swipeable-card-container {
      margin: 0px;

      .swipeable-item .padding-removed .swiper-view-container .full-width .card-content {
        padding-left: 16px;

        .remove-button {
          margin-left: 0px;
          margin-right: 10px;
        }
      }
    }
  }
}
.overview-building-codes {
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    formly-field {
      padding-bottom: 0px;
      padding-right: 0px;
    }
    .new-row {
      flex-basis: 100%;
      height: 0;
      padding: 0px;
    }
  }
  .building-codes-input-fields {
    app-formly-field-simple-input {
      ion-item {
        --padding-start: 10px;
        --inner-padding-end: 0px;
      }
    }
  }
  .building-codes-select-fields {
    padding-left: 10px;
    ion-item {
      width: var(--vrsk-field-width-m);
    }
  }
  .sup-color {
    color: var(--vrsk-dark-blue);
  }
  .padding-left-20 {
    padding-left: 20px;
  }
  .padding-left-15 {
    padding-left: 15px;
  }
  .row-container {
    padding-right: 15px;
    padding-left: 2px;
    padding-bottom: 5px;
  }
  .display-space-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
    display: flex;
  }
}
.survey-details-container {
  app-formly-question-wrapper {
    ion-item.question-wrapper-ion-item {
      --padding-end: 10px;
      margin-bottom: 5px;
      margin-top: 0px;
    }
  }
}

.used-non-sprinklered-container, .unused-area-container {
  .area-input-with-label {
    .field-container {
      .field-input-block .field-unit#field-unit {
        margin-right: 3px;
      }

      ion-input input {
        padding-right: 0px;
        padding-left: 5px;
      }
    }
  }
}

.used-non-sprinklered-container {

  .formly-flex-container {
    .area-input {
      app-formly-field-simple-input {
        div.field-input-block {
          width: var(--vrsk-field-width-m);
        }
      }
    }
    .description-input {
      app-formly-field-simple-input {
        div.field-input-block {
          width: 442px;
        }
      }
    }
    .area-modifier-select {
      app-formly-field-simple-select {
        div.field-vertical-select-container{
          width: 298px;
        }
      }
    }
    .first-checkbox {
      app-formly-field-checkbox {
        ion-grid {
          margin-top: 13px;
        }
        ion-checkbox {
          height: 41px;
          width: 41px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.excessive-single-story-height-container {
  .formly-flex-container {
    .description-input {
      app-formly-field-simple-input {
        div.field-input-block {
          width: 348px;
        }
      }
    }

    .area-input {
      app-formly-field-simple-input {
        div.field-input-block {
          width: var(--vrsk-field-width-m);
        }
      }
    }
    
  }
  
  app-sprinkler-field-array {
    ion-grid.grid-container {
      margin-left: -6px;
    }
  }
}
// For ppa searchbar search result popover
ion-popover#searchbarPopover {
  --height: auto;
}
.public-protection-container  {
  .section-two-evaluation-container{
    app-formly-simple-section-wrapper .simple-section-container { 
    .main-section-item{
      margin-right: 20px;
    }
  }
  .question-wrapper-container{
    .question-wrapper-title{
      app-title{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 5px;
      }
    }
  }
  .simple-section-container .hydrant-input .hydrant-with-outlet 
  .question-wrapper-container .question-wrapper-item {
    margin-left: 2px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    --padding-start: 0px; 
    --padding-end: 10px;   
  }
  .simple-section-container {
    ion-row .default-content-style{
      margin-bottom: 20px;
    }
  }
  
  .hydrant-input .action-needed{
    padding: 15px;
    border: 1px solid #999999;
    width: 60%;
    border-radius: 10px;
    height: 47px;
    background-color: #EBF1F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    formly-template > div{
      display: flex;
      gap: 6px;
      align-items: center;
      .action-needed-icon{
        margin-right: 4px;        
        display: inline-flex;
        width: 12px;
        height: 12px;
        border: 1px solid var(--vrsk-black-color);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        font-size: 9px;
      }
    }
  }
  .calculator-wrapper{
    display: flex;
    flex-grow: 1;
    justify-content: space-between;      
    flex-direction: column;      
    padding-bottom: 0px;
    padding-right: 20px;

    .full-width{
      height: 41px;
    }

    formly-field-image{
      border: 1px dashed #168BF6;
      border-radius: 3px;
    }
  }  
}
  .section-evaluation-result{
    display: flex;
    align-items: flex-start;
    margin-right: 0px;
    flex-direction: column;
    width: auto;
    
    .section-evaluation-result-row{
      width: 100%;
      .summary-row-container{
        .summary-entry{
          margin-top:2.5px;
          margin-bottom:2px;
          margin-left: 1px;
          margin-right: 18px;
          border-radius: 3px;
        }
      }
    }
    .new-row {
      margin-top: 0px !important;
    }

    .row-label {
      display: flex;
      flex-grow: 1;
      background-color: #C1D8F2;
      width: 100%;
      height: 60px;
    }

    .first-row {
      padding-bottom: 0px;
      width: 108px;
      margin-bottom: -4px;
    }
    .first-label{
      display: flex;
      flex-grow: 1;
    }
  }
  .action-needed-container{
    display: flex;
    flex-wrap: nowrap;
    padding-right: 10px;
  }
  .action-width{
    flex:1 0 auto;
  }
  .action-needed-button{
    padding-left: 17px;
    formly-field-image { 
    ion-button.action-btn{
      background-color: #00358E;
        color: white;
        border-radius: 25px;
        height: 40px;
        width: 188px;
        margin-top: 5px;
        font-size: var(--vrsk-font-size-smaller);
        font-weight: var(--vrsk-font-weight-normal);
    }
  }
  }
  .section-two-outside-section > app-formly-simple-section-wrapper{ 
    .simple-section-container .main-section-item{    
      .padding-bottom-20{
        padding-bottom: 25px;
      }
    }
  }
}

.public-protection-container-outer{
  app-formly-simple-section-wrapper{
  .simple-section-container .main-section-item  .padding-bottom-20{
    padding-bottom: 34px;
  }
}
}


// Alarm-supervisory-containers
.alarm-supervisory-container{
  .simple-section-container {
    .main-section-item {
      ion-row.section-title-row {
        padding-right: 20px;
      }
      ion-row.section-content-row {
        margin-right: 15px;
      }
    }
  }
  
}
.alarm-supervisory-sheet-container{
  .conducted-by-width{
    margin-right: 40px;
  }
}

// Building Conditions Container
.building-conditions-container {
  display: block;
  padding-right: 23px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-left: -7px;
  .building-condition-select {
    app-formly-field-simple-select {
      ion-item.custom-width {
        width: 238px;
      }
      ion-select {
        width: 100%;
      }
    }
  }
}

//acceptance Testing
.acceptance-test-container {
  .flushing-hydrostatic-section {
    app-formly-question-wrapper {
      .question-wrapper-container {
        .question-wrapper-item {
          margin-top: 3px;
          margin-right: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .acceptance-test {
    app-formly-simple-section-wrapper {
      .simple-section-container {
        .main-section-item {
          margin-right: 0px;
        }
      }
    }
  }

}