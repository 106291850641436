// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  --ion-light-gray-background: #f6f6f6;
  --ion-light-gray-shade: #ebf1f680;
  --ion-light-silver-shade: #f5f5f5;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** Vrsk Orders List **/
  --vrsk-orders-list-item-color: #333333;
  --vrsk-orders-list-item-secondary-color: #929292;
  --vrsk-orders-list-item-icon-color: #168bf6;
  --vrsk-orders-list-item-check-icon-color: #005db8;
  --vrsk-orders-list-item-odd-bg: #f1f1f1;
  --vrsk-orders-list-item-even-bg: #ffffff;
  --vrsk-orders-list-item-odd-hover-bg: #d1dde8;
  --vrsk-orders-list-item-even-hover-bg: #e4f2fd;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-light-red: #ba1b1b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-medium-dark-gray: #636363;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: "Roboto";
  --ion-font-style: normal;
  --ion-border-color: #dcdcdc;
  // --ion-text-color: #cf3c4f;

  --ion-buffer-background: #9d9fa6;
  --ion-progress-background: #2ecddc;
  --ion-button-color: #00358e;
  --ion-gray-background: #e5e5e5;
  --ion-order-selected: #acd4fa8a;
  --order-selected: #f1f1f1;

  --vrsk-fontStyle: normal;
  --vrsk-fontFamily: "Roboto";
  --vrsk-fontStyleItalic: italic;
  --vrsk-fontFamilyMono: "Roboto Mono";

  --vrsk-font-weight-thin: 100;
  --vrsk-font-weight-elight: 200;
  --vrsk-font-weight-light: 300;
  --vrsk-font-weight-normal: 400;
  --vrsk-font-weight-medium: 500;
  --vrsk-font-weight-sbold: 600;
  --vrsk-font-weight-bold: 700;
  --vrsk-font-weight-ebold: 800;
  --vrsk-font-wieght-bolder: 900;
  --vrsk-font-weight-base: 400;
  --vrsk-font-weight-small: 700;
  --vrsk-font-size-sbase: 11px;
  --vrsk-font-size-base: 12px;
  --vrsk-font-size-vsmall: 13px;
  --vrsk-font-size-small: 14px;
  --vrsk-font-size-medsmall: 15px;
  --vrsk-font-size-medium: 16px;
  --vrsk-font-size-smaller: 17px;
  --vrsk-font-size-normal: 18px;
  --vrsk-font-size-vnormal: 17px;
  --vrsk-vsmall-line-height: 15px;
  --vrsk-font-size-med: 20px;
  --vrsk-font-size-medlarge: 24px;
  --vrsk-font-size-medvlarge: 25px;
  --vrsk-font-size-large: 22px;
  --vrsk-font-size-xlarge: 28px;
  --vrsk-font-size-extralarge: 32px;
  --vrsk-smaller-font-line-height: 20px;
  --vrsk-extralarge-font-line-height: 30px;
  --vrsk-vsmall-font-line-height: 15px;
  --vrsk-white-color: #ffffff;
  --vrsk-black-color: #000000;
  --vrsk-dark-gray-color: #555555;
  --vrsk-border-color-gray: 1px solid #c4c4c4;
  --vrsk-gray: #c6c6c8;
  --vrsk-light-gray: #333333;
  --vrsk-light-brown: #929292;
  --vrsk-dark-blue: #168bf6;
  --vrsk-dark-global-blue: #00358d;
  --vrsk-light-gray-background: #f6f6f6;
  --vrsk-primary-blue: #00358e;
  --vrsk-primary-blue-disabled: #00358E80;
  --vrsk-disabled-button: #859bc8;
  --vrsk-primary-sky: #65cad9;
  --vrsk-primary-light-blue-shade: #cdedf0;
  --vrsk-app-icon-color: #73849a;
  --vrsk-dark-label: #191c1d;
  --vrsk-border-color-dc: 1px solid #dcdcdc;
  --vrsk-border-color-complete: 1px solid #d7d8da;
  --vrsk-border-color-blue: 1px solid #00358e;
  --vrsk-yellow: #ffc600;
  --vrsk-red: #eb5757;
  --vrsk-error: #ee3932;
  --vrsk-completeButton: #808289;
  --vrsk-border-color-96: 1px solid #969696;
  --vrsk-gray-background: #f5f6f9;
  --vrsk-light-color: #f8f8f8;
  --vrsk-progressBar: #9d9fa6;
  --vrsk-progressBarActive: #2ecddc;
  --vrsk-light-gray1: #d8d8d8;
  --vrsk-light-gray2: #c4c4c4;
  --vrsk-light-gray3: #fafafa;
  --vrsk-light-gray4: #bdbdbd;
  --vrsk-light-gray5: #999999;
  --vrsk-light-gray6: #f1f1f1;
  --vrsk-light-gray7: #636363;
  --vrsk-light-gray8: #f7f7f7;
  --vrsk-box-shadow: 0px 4px 4px #0000000d;
  --vrsk-box-shadow2: 0px 3px 10px 0px #cccccc80; // elevation 3 ?
  --vrsk-box-shadow3: 0px 4px 8px rgba(0, 0, 0, 0.12); // elevation 2
  --vrsk-box-shadow4: 0px 2px 4px 0px #00000026; // elevation 1
  --vrsk-box-shadow5: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  --vrsk-blue1: #18539a;
  --vrsk-gray1: #76797b;
  --vrsk-gray2: #717171;
  --vrsk-toggle-box-shadow: 0px 1px 2px #00000040;
  --vrsk-rgba246: #f6f6f6;
  --vrsk-rgba113: #717171;
  --vrsk-rgba51: #333333;
  --vrsk-rgba150: #96969621;
  --vrsk-rgba0: #000000;
  --vrsk-toggle-background: #f9f9f9;
  --vrsk-text-transform: uppercase;
  --vrsk-text-transform-capitalize: capitalize;
  --vrsk-line-height-base: 16px;
  --vrsk-active-btn-color: #4591f6;
  --vrsk-green: #009d4f;
  --vrsk-blue-bg: #ebf1f6;
  /*Verisk Border Radius*/
  --vrsk-border-radius: 6px;
  --vrsk-light-silver-background: #f5f5f5;
  --vrsk-blue-bg-opacity: #ebf1f67f;
  /*Verisk toaster color code*/
  --vrsk-toast-background-color: #ccebdc;
  --vrsk-toast-border-color: #006a35;
  --vrsk-toast-green-color: #7AC9A2;
  --vrsk-error-red: #eb5757;
  --vrsk-orange: #ff7c33;
  --vrsk-border-gray: #d9d9d9;

  --vrsk-background-opaque: #ffffff80;
  --vrsk-light-blue-opaque: #168bf61a;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-color-step-1000: #f1f1f1;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-toolbar-background-color: var(--ion-color-step-1000);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
