:root {
  // Font sizes
  --vrsk-rf-error-font-path: 13px;
  --vrsk-rf-error-font-message: 12px;  

  // Margins
  --vrsk-landing-section-margin: 50px;
  --vrsk-internal-section-margin: 20px;

  // Remove red icon
  --vrsk-remove-icon-width: 25px;
  --vrsk-remove-icon-height: 25px;
  --vrsk-remove-icon-color: #ee3932;

  // Top Right Error Icon
  --vrsk-rf-error-icon-top-shallow: -40px;

  // Header Navigation
  --vrsk-header-height: 70px;
  --vrsk-header-item-height: 41px;
  --vrsk-header-item-width: 48px;

  // Fields standard sizes
  --vrsk-field-width-m: 114px;
  --vrsk-field-width-ml: 124px;
  --vrsk-field-width-ls: 138px;     // 12.00
  --vrsk-field-width-l: 238px;

  --vrsk-field-padding-s: 0px;
  --vrsk-field-padding-sm: 20px;
  --vrsk-field-width-s: 70px;
  --vrsk-field-height-xs: 41px;
  --vrsk-field-height-s: 51px;
  --vrsk-field-height-m: 60px;
  --vrsk-field-label-height-s: 10px;
  --vrsk-checkbox-size-m: 42px;
  --vrsk-section-width-l: 50%;
  --vrsk-padding-s: 1px;

  // Fields standard colors
  --vrsk-pale-aqua-color: #C1D8F2;
  --vrsk-red-orange-color: #FF6A42;

  // Sections
  --vrsk-section-header-height: 84px;
  --vrsk-question-height: 60px;
  --vrsk-field-height-md: 64px;
}