/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';


$fontStyle: normal;
$fontFamily: 'Roboto';
$fontStyleItalic: italic;
$fontFamilyMono: 'Roboto Mono';

$font-weight-thin: 100;
$font-weight-elight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-sbold: 600;
$font-weight-bold: 700;
$font-weight-ebold: 800;
$font-wieght-bolder: 900;

$font-weight-base: 400;
$font-weight-small: 700;
$font-size-sbase: 11px;
$font-size-base: 12px;
$font-size-vsmall: 13px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-smaller: 17px;
$font-size-normal: 18px;
$font-size-large: 22px;
$font-size-midxlarge: 25px;
$font-size-xlarge: 28px;
$font-size-extralarge: 32px;
$font-size-nxl: 20px;
$white-color: #ffffff;
$black-color: #000000;
$border-color-gray: 1px solid #c4c4c4;
$gray: #c6c6c8;
$light-gray: #333333;
$light-brown: #929292;
$blue: #007aff;
$dark-blue: #168bf6;
$dark-global-blue: #00358d;
$light-gray-background: #f6f6f6;
$primary-blue: #00358e;
$app-icon-color: #73849a;
$dark-label: #191c1d;
$border-color-dc: 1px solid #dcdcdc;
$border-color-complete: 1px solid #d7d8da;
$border-color-completeActive: 1px solid $primary-blue;
$yellow: #ffc600;
$completeButton: #808289;
$border-color-96: 1px solid #969696;
$border-color-99: 1px solid #999999;
$gray-background: #f5f6f9;
$progressBar: #9d9fa6;
$progressBarActive: #2ecddc;
$light-gray1: #d8d8d8;
$light-gray2: #c4c4c4;
$white-border: #c4c4c4;
$light-gray3: #fafafa;
$box-shadow: 0px 4px 4px #0000000d;
$blue1: #18539a;
$gray1: #76797b;
$gray2: #717171;
$toggle-box-shadow: 0px 1px 2px #00000040;
$rgba246: #f6f6f6;
$rgba113: #717171;
$rgba51: #333333;
$rgba150: #96969621;
$rgba0: #000000;
$green-background: #cae9da;
$progress-background: #ebf8f9;
$deleted-background: #f4cfce;

$text-transform: uppercase;
$text-transform-capitalize: capitalize;
$line-height-base: 16px;
$active-btn-color: #4591f6;

* {
  font-style: var(--ion-font-style);
  --border-color: #dcdcdc;
}

.ion-filter-modal {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 80%;
    height: 85%;
    --border: $border-color-dc;
    --box-shadow: 0px 6px 4px #0000001a;
    --border-radius: 15px;
  }
}

.ion-search-modal {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 80%;
    height: 85%;
    --border: $border-color-dc;
    --box-shadow: 0px 6px 4px #0000001a;
    --border-radius: 15px;
  }
}


.ion-remove-group-modal {
  align-items: center;
  justify-content: center;
  --backdrop-opacity: 0.5;

  &::part(content) {
    width: 50%;
    height: 20%;
    --border: $border-color-99;
    --box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    --border-radius: 15px;
  }
}

.ion-group-modal {
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::part(content) {
    width: 565px;
    height: 54%;
    --border: 1px solid #dcdcdc;
    --box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
    --border-radius: 15px;
  }
}

.image-preview-modal {
  &::part(content) {
    width: 100%;
    height: 100%;
  }
}

.confirmation-popup {
  button.alert-button:nth-child(1) {
    color: $blue;
  }

  button.alert-button:nth-child(2) {
    color: $blue !important;
  }

  .alert-message.sc-ion-alert-ios {
    font-size: var(--vrsk-font-size-smaller);
    font-weight: 500;
  }
}

.mat-typography {
  .bg {
    background-color: #efefef;
  }

  .icon-person-order {
    color: #3880ff;
  }

  .bold-label {
    font-weight: $font-weight-bold;
  }

  /* Used for all Page Titles */
  h1 {
    font-weight: $font-weight-bold;
    font-size: 36px;
    color: #000000;
  }

  h3.h3-title {
    white-space: normal;
    width: 100%;
  }

  .d-block {
    display: block;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }
}
// toaster
.toaster {
  --background: var(--vrsk-toast-background-color);
  --color: var(--vrsk-light-gray);
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--vrsk-toast-border-color);
  font-size: $font-size-nxl;
  font-style: $font-weight-bold;
  width: 70%;
  position: absolute;
  right: 10px;
  left: auto;
  bottom: 10px;
  top: auto;

  &::part(button) {
    text-decoration-line: underline;
    --button-color: var(--vrsk-light-gray);
    margin-right: 25px;
  }

  &.error-toast {
    --background: var(--vrsk-error);
    --color: var(--vrsk-white-color);
  }

  &::part(message) {
    text-align: center;
  }
  // Use this for green toasters
  &.green-toaster {
    --background: var(--vrsk-toast-green-color);
    width: 70%;
    right: 36px;
    &::part(message) {
      text-align: left;
      margin-left: 25px
    }
    &::part(container) {
      align-items: flex-start;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &::part(button) {
      margin-right: 5px;
      font-size: 20px;
      line-height: 20px;
    }
  }
}
.green-toaster {
  .toast-button-group {
    display: flex;
    min-height: 110px 
  }
  .toast-button{
    font-size: 20px;
  }
  
}
 
//for large font toaster messages
.error-toast.toast-xl{
  font-size: 20px;
  --min-height: 81px;
  right: 36px;
  left: auto;
  top : auto;
  width: 76%;
  --padding-top: 5px;
  --padding-bottom: 5px;
  &::part(message) {
    margin-left: 25px;
  }
  &::part(container) {
    min-height: 71px;
    padding-top: 5px;
    padding-bottom: 5px;
}
&::part(button) {
  font-size: 20px;
  line-height: 20px;
}
}



.general-alert {
  .alert-button {
    color: var(--vrsk-dark-blue) !important;
  }
}

.toast-class {
  --background: var(--vrsk-toast-background-color);
  --color: var(--vrsk-light-gray);
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--vrsk-toast-border-color);
  font-size: $font-size-nxl;
  font-style: $font-weight-bold;
  box-sizing: border-box;
  position: absolute;
  width: 552px;
  right: 10px;
  left: auto;
  bottom: 10px;
  top: auto;

  &::part(button) {
    text-decoration-line: underline;
    --button-color: var(--vrsk-light-gray);
    margin-right: 25px;
  }

  &::part(message) {
    text-align: center;
  }
}

/* Portrait mode */
@media (orientation: portrait) {
  .toast-class {
    max-width: 90%;
    bottom: 10px;
    right: 10px;
  }

  .toaster {
    max-width: 90%;
    bottom: 10px;
    right: 10px;
  }
}

// /* Landscape mode */
@media (orientation: landscape) {
  .toast-class {
    max-width: 50%;
    bottom: 10px;
    right: 10px;
  }

  .toaster {
    max-width: 50%;
    bottom: 10px;
    right: 10px;
  }
}

// For Toast for Field Errors
.error-toast {
  --background: #f4cfce;
  --color: var(--vrsk-dark-gray-color);
  --border-color: var(--vrsk-error);
  --border-style: solid;
  --border-width: 2px;
  --border-radius: 10px;
  --max-width: 88%;
  --max-height: 200px;
  --min-height: 85px;
  width: 100%;

  font-size: $font-size-medium;

  &::part(button) {
    --button-color: var(--vrsk-black-color);
    font-size: $font-size-nxl;
  }

  &::part(container) {
    align-items: flex-start;
    overflow-x: auto;
  }
}

// End

@media (orientation: landscape) {
  .ion-filter-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
      width: 80%;
      height: 75%;
      --border: 1px solid #dcdcdc;
      --box-shadow: 0px 6px 4px #0000001a;
      --border-radius: 15px;
    }
  }

  .ion-remove-group-modal {
    align-items: center;
    justify-content: center;
    --backdrop-opacity: 0.5;

    &::part(content) {
      width: 50%;
      height: 30%;
      --border: $border-color-99;
      --box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      --border-radius: 15px;
    }
  }

  .ion-search-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
      width: 80%;
      height: 76%;
      --border: 1px solid #dcdcdc;
      --box-shadow: 0px 6px 4px #0000001a;
      --border-radius: 15px;
    }
  }


  .ion-group-modal {
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &::part(content) {
      width: 565px;
      height: 80%;
      --border: 1px solid #dcdcdc;
      --box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
      --border-radius: 15px;
    }
  }
}

.font-normal-weight-normal {
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
}

.font-normal-weight-bold {
  font-size: $font-size-normal;
  font-weight: $font-weight-bold;
}

.font-base-weight-normal {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

.font-small-weight-normal {
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
}

.font-vsmall-weight-normal {
  font-size: $font-size-vsmall;
  font-weight: $font-weight-normal;
}

.font-small-weight-light {
  font-size: $font-size-small;
  font-weight: $font-weight-light;
}

.font-medium-weight-normal {
  font-size: $font-size-medium;
  font-weight: $font-weight-normal;
}

.font-medium-weight-light {
  font-size: $font-size-medium;
  font-weight: $font-weight-light;
}

.font-13-weight-medium {
  font-size: 13px;
  font-weight: $font-weight-medium;
}

.font-13-weight-normal {
  font-size: 13px;
  font-weight: $font-weight-normal;
}

.font-14-weight-medium {
  font-size: 14px;
  font-weight: $font-weight-medium;
}

.alert-radio-group.sc-ion-alert-ios,
.alert-checkbox-group.sc-ion-alert-ios {
  max-height: fit-content;
}

ionic-selectable {
  border: $border-color-dc;
  border-radius: 10px;

  .ionic-selectable-inner {
    .ionic-selectable-value {
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 15px;
    }

    .ionic-selectable-icon-template {
      align-self: center;
      align-items: center;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      margin-right: 5px;

      ion-icon {
        color: $primary-blue;
      }
    }
  }
}

ionic-selectable-modal {
  border-radius: 15px;

  &.ionic-selectable-modal {
    ion-header {
      ion-toolbar:first-of-type {
        --border-width: 0 0 1px 0;
        --ion-safe-area-top: 0px;
      }

      ion-toolbar.toolbar-searchbar {
        --background: var(--ion-color-primary-contrast);
        display: flex;
        align-items: center;

        ion-searchbar {
          padding-bottom: 1px;
          padding-top: 1px;
        }
      }
    }
  }

  .header-md::after {
    background-image: none;
  }

  ion-searchbar {
    .searchbar-input-container {
      .searchbar-input.sc-ion-searchbar-md {
        border-radius: 10px;
        box-shadow: none;
        background-color: var(--ion-light-gray-background);
      }
    }
  }

  ion-toolbar {
    --min-height: 67px;
    background-color: $light-gray-background;

    .title {
      padding-left: 29px;
      --color: $light-gray;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;
      letter-spacing: 0.7px;
      color: $light-gray;

      text-align: start;
    }
  }
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

.modal-fullscreen {
  --width: 100%;
  --height: 100%;
  top: 25px;
  --border-radius: 0;
}

.photo-modal-fullscreen {
  --width: 100%;
  --height: 100%;
  top: 0;
  --border-radius: 10px;
}

.modal-wrapper {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}


.schedule-search-results-modal .ion-page {
  top: 40%;
  height: 60vh;
}

.schedule-search-results-modal {
  --background: transparent;
  --backdrop-opacity: 0.5 !important;
}

ion-popover {
  --min-width: 120px;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0) !important;
  --width: max-content;
  // --width: 335px;
  --height: 40%;
}

ion-popover {
  &::part(.popover-content) {
    left: unset !important;
    right: 0px !important;
  }
}

.popover-content {
  left: unset !important;
  right: 0px !important;
}

/* risk form select popover styling */
.formly-select-popover {
  &::part(content) {
    --max-height: 300px;
    --height: auto;
    --overflow: auto;
  }
}

.full-risk-navigation-popover {
  &::part(content) {
    --max-height: 500px;
    border: 2px solid var(--vrsk-light-gray5);
  }
}

// ion-select background for select options
ion-popover {
  .select-option-background {
    --background: var(--vrsk-light-gray6);
  }
}

/*pop over end*/

.alert-button-role-confirm {
  color: var(--vrsk-red) !important;
  font-style: var(--ion-font-style);
  // font-weight: var(--vrsk-font-weight-sbold);
  font-weight: var(--vrsk-font-weight-normal) !important;
  font-size: var(--vrsk-font-size-vnormal);
  line-height: 20px;
}

.alert-title.sc-ion-alert-ios {
  // font-size: 14px !important;
  color: var(--vrsk-black-color);
  font-family: 'Roboto';
  font-style: var(--ion-font-style);
  // font-weight: var(--vrsk-font-weight-sbold);
  font-weight: var(--vrsk-font-weight-medium);
  font-size: var(--vrsk-font-size-smaller);
  line-height: 20px;
}

.alert-sub-title.sc-ion-alert-ios {
  color: var(--vrsk-black-color);
  font-family: 'Roboto';
  font-style: var(--ion-font-style);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-weight: var(--vrsk-font-weight-medium);
  font-size: 17px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.5px;
}

.mapboxgl-ctrl-bottom-right {
  right: 0;
  bottom: 10px !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

.mapboxgl-ctrl-group {
  overflow: hidden;

  .mapbox-gl-draw_ctrl-draw-btn.active, .mapboxgl-ctrl button:not(:disabled):hover {
    background-color: rgb(0 0 0 / 17%);
  }
}

.disableClick {
  pointer-events: none !important;
  border-radius: 0px 0px 30px 30px !important;
}

.cursor-pointer {
  cursor: pointer;
}

ion-loading.maps-spinner.sc-ion-loading-ios-h {
  padding: 4px 9px 6px;
  align-items: flex-start;
  justify-content: center;
  top: 30px;
  width: 100%;
  height: 70px;
  position: absolute;

  .loading-wrapper {
    padding: 7px 17px 0px;
    border-radius: 19px;
    background-color: var(--ion-gray-background);
  }
}

#street-view canvas {
  width: 100% !important;
}

.confirmation-popup button.ion-focused.alert-button {
  background: none;
}

// page widget's styles starts
.app-header {
  ion-toolbar {
    --background: var(--vrsk-white-color);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);

    ion-title {
      font-weight: var(--vrsk-font-weight-base);
      font-size: var(--vrsk-font-size-smaller);
      line-height: var(--vrsk-smaller-font-line-height);
    }
  }
}

// User info banner css

.user-info-widget {
  --ion-grid-padding: 20px;

  $user-info-section-height: 75; // in px

  ion-item {
    --padding-start: 0px;
    --min-height: #{$user-info-section-height}#{'px'};
    border-top-left-radius: #{calc($user-info-section-height / 2)}#{'px'};
    border-bottom-left-radius: #{calc($user-info-section-height / 2)}#{'px'};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

    ion-avatar {
      width: #{$user-info-section-height}#{'px'};
      height: #{$user-info-section-height}#{'px'};
      margin: 0px;
    }

    .detail-section {
      .title {
        font-weight: var(--vrsk-font-weight-base);
        font-size: var(--vrsk-font-size-smaller);
        line-height: var(--vrsk-smaller-font-line-height);
        margin: 0px;
      }

      .sub-title {
        font-weight: var(--vrsk-font-weight-base);
        font-size: var(--vrsk-font-size-vsmall);
        line-height: var(--vrsk-vsmall-font-line-height);
        color: var(--vrsk-dark-gray-color);
      }
    }

    .icon-logout {
      font-size: var(--vrsk-font-size-med);
    }
  }
}

.streetview-wrapper {
  position: relative;
}

.alert-data {
  box-sizing: border-box;

  position: relative;
  width: 415px;
  height: 1350px;

  border: 1px dashed #7b61ff;
  border-radius: 5px;
}

.streetview-expand {
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 50%;
  background-color: white;
  padding: 6px;
  border-radius: 4px;
}

.amplify-button {
  color: var(--ion-button-color);
  height: 52px;
  width: 802px;
  left: 0px;
  top: 0px;
  border-radius: 12px;
  background: #ffffff !important;
  font-weight: var(--vrsk-font-weight-medium);
  font-size: var(--vrsk-font-size-medium);
  line-height: 19px;
}

.size-text {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 10px;
}

.button-native {
  background-color: #fff !important;
  color: #168bf6 !important;
}

.bg_content {
  --background: var(--ion-background-color, #e5e5e5) !important;
}

::ng-deep .action-sheet-title.sc-ion-action-sheet-ios {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: -16px;
  /* padding-bottom: 13px; */
  color: var(--color, var(--ion-color-step-400, #999999));
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}

::ng-deep .action-sheet-cancel.sc-ion-action-sheet-ios {
  font-weight: 400;
}

.action-sheet-fixed-width {
  .action-sheet-wrapper {
    align-items: center;

    .action-sheet-container {
      width: 76%;
      margin: 0 auto;

      .action-sheet-button {
        font-size: var(--vrsk-font-size-smaller);
        font-weight: var(--vrsk-font-weight-normal);
      }

      .action-sheet-title {
        padding-top: 0px;
      }

      .action-sheet-cancel.sc-ion-action-sheet-ios {
        font-weight: var(--vrsk-font-weight-sbold);
        font-size: var(--vrsk-font-size-smaller);
      }

      .action-sheet-group.sc-ion-action-sheet-ios {
        margin-bottom: 12px;
      }

      .action-sheet-destructive.sc-ion-action-sheet-ios {
        color: var(--vrsk-red);
      }
    }
  }
}

.button-inner {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: left;
  height: 100%;
  z-index: 1;
}

.modal-halfscreen {
  --width: 100%;
  --height: 50%;
  --border-radius: 0;
}

// Risk Form Styles
@import './assets/styles/risk-form.scss';
@import './assets/styles/risk-form-variable.scss';

// Underwriting Form Styles - Screen
@import './assets/styles/underwriting-form.scss';

.full-width {
  width: 100%;
}

.btn-wrapper {
  height: 41px;
}

.custom-searchBar {
  --icon-color: var(--vrsk-dark-blue);
  padding: 10px;

  ::ng-deep .searchbar-input-container {
    height: 40px !important;
    --background: var(--vrsk-white-color);
    border-radius: 3px;
    border: 1px solid var(--vrsk-light-gray5);
  }

  ::ng-deep .searchbar-input-container ion-icon {
    right: auto !important;
    left: 5px !important;
  }

  ::ng-deep .searchbar-input-container input {
    padding-inline-start: 30px !important;
  }
}

.recommendation-modal {
  --width: 100%;
  --height: 70%;
  --border-radius: 10px;
}

.order-detail-modal {
  --width: 100%;
  --height: 55%;
  --border-radius: 10px;
}

ion-modal.three-sixty-modal::part(content) {
  --height: 30%;
  --width: 85%;
  --border-radius: 4px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #eeeeee;
}

.order-detail-modal {
  --width: 100%;
  --height: 50%;
  --border-radius: 10px;
}

ion-modal.three-sixty-modal::part(content) {
  --height: 30%;
  --width: 85%;
  --border-radius: 4px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #eeeeee;
}


.comment-sheet-modal {
  --width: 100%;
  --height: 60%;
  --border-radius: 15px !important;
}

// order details ui refactoring
.bg-wrapper {
  border-radius: 3px;
  background: var(--vrsk-white-color);
  padding: 20px;
  box-shadow: var(--vrsk-box-shadow5);
}

.margin-5 {
  margin: 5px;
}

.priortitle {
  font-family: $fontFamily;
  font-weight: $font-weight-medium;
  font-size: $font-size-smaller;
  color: $black-color;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.cr-icon {
  font-size: $font-size-nxl;
}

.sub-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editButton {
  height: 42px;
  width: 114px;
  border: 1px solid $dark-blue;
  border-radius: 25px;
  font-weight: $font-weight-normal;
  font-size: $font-size-smaller;
  color: $dark-blue;
  margin: 0;
}

.details-wrapper {
  border: 1px solid var(--vrsk-light-gray6);
  padding: 15px 10px;

  ion-label {
    color: var(--vrsk-dark-gray-color);
    font-family: $fontFamily;
    font-size: $font-size-vsmall;
    font-weight: $font-weight-normal;
  }

  .details {
    color: $black-color;
    font-family: $fontFamily;
    font-size: $font-size-smaller;
    font-weight: $font-weight-normal;
  }
}

.up-arrow {
  color: $dark-blue;
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

a {
  color: #168BF6;
}

.pb-0 {
  padding-bottom: 0;
}

.d-flex {
  display: flex;
}

.ml-10 {
  margin-left: 10px;
}

.mt-0 {
  margin-top: 0;
}

.mt-15 {
  margin-bottom: 15px;
}

.mt-22 {
  margin-top: 22px;
}

.streetview-man {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 4px;
  font-size: $font-size-midxlarge;
}

.mapview-segment {
  --background: var(--vrsk-white-color);
  border-radius: 56px;
  width: 176px;
  --border-width: 3px;
  --border-style: solid;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 3px;
  padding-right: 3px;

  ion-segment-button {
    --background-checked: var(--vrsk-dark-blue);
    --color-checked: var(--vrsk-white-color);
    --border-radius: 20px;
    width: 50px;
    --indicator-color: transparent;
    --padding: 10px;
    --margin-right: 2px;
    --margin-left: 2px;
    --margin-bottom: 2px;
    --margin-top: 2px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 400;

    &.segment-button-checked {
      box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
    }
  }
}

.order-details-modal {
  --width: 100%;
  --height: 75%;
  --border-radius: 10px;
}

@media (orientation: landscape) {
  .order-details-modal {
    --width: 100%;
    --height: 100%;
    --border-radius: 10px;
  }
}


.common-modal-header {
  &.header {
    background-color: var(--vrsk-primary-blue);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-content {
    color: var(--vrsk-white-color);
    margin-left: 50px;
    font-weight: var(--vrsk-font-weight-light);
    font-size: var(--vrsk-font-size-normal);
  }

  .cancel-btn {
    color: var(--vrsk-white-color);
    width: 104px;
    height: 41px;
    border: 1px solid var(--vrsk-white-color);
    border-radius: 25px;
    font-weight: var(--vrsk-font-weight-normal);
    font-size: var(--vrsk-font-size-smaller);
    line-height: 20px;
    margin-right: 5px;
  }
  

  .complete-order-btn {
    color: var(--vrsk-primary-blue);
    width: 238px;
    height: 41px;
    --background: var(--vrsk-white-color);
    border-radius: 25px;
    font-weight: var(--vrsk-font-weight-normal);
    font-size: var(--vrsk-font-size-smaller);
    line-height: 20px;
    margin-right: 50px;
  }

  .complete-disabled-order-btn {
    color: var(--vrsk-primary-blue);
    width: 238px;
    height: 41px;
    border-radius: 25px;
    font-weight: var(--vrsk-font-weight-normal);
    font-size: var(--vrsk-font-size-smaller);
    line-height: 20px;
    margin-right: 50px;
    --background: var(--vrsk-disabled-button);
    border: 1px var(--vrsk-disabled-button);
    outline: none;
    box-shadow: none;
  }
}

.customer-req-indicator{
  visibility: hidden;
}